import {useEffect, useState} from "react";
import del from "../assets/delete-bin.svg";
import eye from "../assets/eye.png"
import {useNavigate} from "react-router-dom";
import tick from "../assets/co-coaches-tick.svg";
import cross from "../assets/co-coaches-cross.svg";


const CoCoachLinkGrid = (props) => {

    const [activeLinks, setActiveLinks] = useState([])
    const [showPopup, setShowPopup] = useState(false)
    const [storedLink, setStoredLink] = useState(null)
    const handle = props.handle
    const navigate = useNavigate()

    useEffect(() => {
        setActiveLinks(props.activeLinks)
    }, [props]);

    const handleDeleteClick = (ev, link) => {
        if(!ev) return;
        setShowPopup(true);
        setStoredLink(link)
    }

    const cancelDelete = (ev) => {
        if(!ev) return;
        setShowPopup(false);
        setStoredLink(null)
    }

    const acceptDelete = (ev) => {
        if(!ev) return;
        setShowPopup(false)
        handle(storedLink.id, false)
        setStoredLink(null)
    }

    return (
        <>
            <div className="settings-form-container co-coaches"
                 style={{display: activeLinks && activeLinks.length ? "block" : "none"}}>
                <div className="settings-form-title">Shared With Me</div>
                <div className="settings-invites-grid">
                    <div className="settings-co-coaches-row header medium">
                        <div className="settings-co-coaches-column email">Origin</div>
                        <div className="settings-co-coaches-column expiry-date">Status</div>
                        <div className="settings-co-coaches-column controls"/>
                    </div>
                    {activeLinks && activeLinks.map((active_link, idx) => {
                        return (
                            <div className="settings-co-coaches-row shared" key={idx}>
                                <div className="settings-co-coaches-column email">{active_link.email}</div>
                                <div className="settings-co-coaches-column expiry-date recolour medium">
                                    <div className={`co-coaches-indicator ${active_link.status === "Active" ? "active" : "pending"}`}/>
                                    {active_link.status}
                                </div>
                                <div className="settings-co-coaches-column controls">
                                    <div className="settings-co-coaches-column-button"
                                         onClick={() => navigate(`/co-coach/${active_link.id}?view=true`)}>
                                        <img src={eye} alt="View"/>
                                    </div>
                                    <div className="settings-co-coaches-column-button"
                                         onClick={ev => handleDeleteClick(ev, active_link)}>
                                        <img src={del} alt="Delete"/>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
            {showPopup ?
                <>
                    <div className="page-mask" onClick={cancelDelete}/>
                    <div className="co-coaches-delete-popup">
                        <div className="co-coachs-delete-popup-title bold">Are you sure you want to delete</div>
                        <div className="co-coaches-delete-popup-text regular">{storedLink && storedLink.email}</div>
                        <div className="co-coaches-delete-popup-controls-container">
                            <div className="co-coaches-delete-popup-button">
                                <img src={tick} alt="Yes" onClick={acceptDelete}/>
                            </div>
                            <div className="co-coaches-delete-popup-button">
                                <img src={cross} alt="No" onClick={cancelDelete}/>
                            </div>
                        </div>
                    </div>
                </>
                : null
            }
        </>
    )
}


export default CoCoachLinkGrid