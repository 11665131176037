import React, {useEffect, useState} from 'react';
import {useNavigate} from "react-router-dom";
import TextUtils from "../utils/TextUtils";
import auth from "../api/auth";
import stripe_api from "../api/protected/memberships"
import GenericDialog from "../components/GenericDialog";
import TeamsCardContainer from "../components/dashboard/TeamsCardContainer";
import SweetDreamsCard from "../components/dashboard/SweetDreamsCard";
import TasksCard from "../components/dashboard/TasksCard";
import ROICard from "../components/dashboard/ROICard";


const Dashboard = () => {
    const navigate = useNavigate()
    const buttons = [
        {"id": 1, "text": "Confirm and Proceed to Payment"},
        {"id": 2, "text": "Return to plan options"}
    ];
    const popUpText = () => {
       return (
           <div className="accreditation-popup-text">
               <div className="accreditation-popup-text-paragraph">
                   You are just about to pay for the {enquiryTier && TextUtils.toTitleCase(enquiryTier)} plan.
               </div>
               <div className="accreditation-popup-text-paragraph">
                   In order to be able to access the full functionality, you must be an accredited team coach, and
                   you will need to upload details of your team coach training and/or accreditation. This is to ensure
                   that only trained users who understand team coaching and the necessity for a psychologically safe
                   environment for debriefing diagnostics are provided access.
               </div>
               <div className="accreditation-popup-text-paragraph">
                   We accept suitably qualified Team Coaches from the systemic programmes listed below:
                   <ul>
                       <li>CMI Team Coach - Foundation/Practitioner/Senior Practitioner</li>
                       <li>GTCI Team Coach - Foundation/Practitioner/Senior Practitioner (either PERILL or STC
                           streams)</li>
                       <li>AOEC / Renewal Associates Systemic Team Coaching Certificate or Diploma</li>
                       <li>Henley Business School:Professional Certificate in Team and Systemic Coaching</li>
                       <li>ORSC</li>
                       <li>Ruth Wageman’s 6 Conditions</li>
                       <li>Team Coach International</li>
                       <li>Smurfit Business School (Dublin)</li>
                       <li>High Performance Team Coaching System (Carr)</li>
                       <li>Another EMCC & ICF accredited systemic team coaching programme at the same level</li>
                   </ul>
               </div>
               <div className="accreditation-popup-text-paragraph">
                   You will now be directed to the payment screen and you will be charged for your annual
                   subscription to the {enquiryTier && TextUtils.toTitleCase(enquiryTier)} plan. Following successful payment,
                   you will be asked to upload details of your accreditation. Approval of accreditation will be
                   within 7 working days. You will not be able to access the full features of
                   the {enquiryTier && TextUtils.toTitleCase(enquiryTier)} plan until you have uploaded these documents
                   and they have been verified, even if your payment has been taken.
               </div>
           </div>
       )
    }

    const [showAccreditationPopUp, setShowAccreditationPopUp] = useState(false);
    const [enquiryTier, setEnquiryTier] = useState("")

    const handleAccreditationDialogResult = (res) => {
        if (!res || !enquiryTier) return;
        if (res === "1") {
            switch (enquiryTier) {
                case 'silver':
                    stripe_api.CreateCheckoutSession('silver', "/dashboard")
                    break;
                case 'gold':
                    stripe_api.CreateCheckoutSession("gold", "/dashboard");
                    break;
            }
        }
        else if (res === "2") navigate("/pricing-plans")
        setShowAccreditationPopUp(false)
    }

    useEffect(() => {
        auth.hasSession().then((session) => {
            if (!session) window.location.assign("/logout");
            // Membership Enquiry after login/register
            let tier = stripe_api.ExecuteEnquiry();
            if (!tier) return;
            setEnquiryTier(tier)
            setShowAccreditationPopUp(true)
        });
    }, [])


    return (
        <div className="dashboard-page-background-container">
            <div className="dashboard-page-container">
                <ROICard/>
                <TasksCard/>
                <TeamsCardContainer/>
                <SweetDreamsCard/>
            </div>
            {showAccreditationPopUp && showAccreditationPopUp ?
                <GenericDialog text={popUpText()} title={"Membership Accreditation"} buttons={buttons}
                               result={handleAccreditationDialogResult.bind(this)} wide={true}/>
                : null
            }
        </div>
    )
}

export default Dashboard
