import {useEffect, useState} from "react";


const ComparisonSummaryChart = (props) => {
    const surveyData = props.surveyData
    const [tmData, setTmData] = useState([])
    const [tlData, setTlData] = useState([])
    const [stData, setStData] = useState([])

    const [activeHoverSection, setActiveHoverSection] = useState("")
    const [activeHoverScores, setActiveHoverScores] = useState({"tm": 0, "tl": 0, "st": 0})
    const [hoverX, setHoverX] = useState(0)
    const [hoverY, setHoverY] = useState(0)

    const [mouseOverPopup, setMouseOverPopup] = useState(false)
    const [activeHoverEvent, setActiveHoverEvent] = useState(null)
    const [activeHoverIdx, setActiveHoverIdx] = useState(0)

    const themes = [
        "Alignment", "Clarity", "Enthusiasm & Contribution", "Enjoyment",
        "Support & Collaboration", "Environmental Awareness", "Stakeholder & Customer Alignment",
        "Talent & Resource Acquisition", "Stakeholder & Customer Communication",
        "Trust & Integrity in Relationships", "Skills & Expertise", "Respect & Trust", "Camaraderie & Cohesion",
        "Safe Communication", "Values Diversity & Inclusion", "Appropriate Resourcing", "Team Agility",
        "Accountability", "System Efficacy", "Team Protection", "Development Planning", "Learning Relevance",
        "Embedded Learning", "Developmental Orientation", "Reflective Learning", "Vision & Context",
        "Collaborative & Empowering Leadership", "Interpersonal Leadership", "Leadership Courage",
        "Inspirational Leadership"
    ]

    useEffect(() => {
        if(!surveyData) return
        formatData()
    }, [surveyData])

    const getAverageScore = (scores) => {
        if(!scores.length) return;
        let total = 0
        for(const score of scores) {
            total += score.score
        }

        return total / scores.length
    }

    const convertToPercentage = (score) => {
        if(!score && score !== 0) return;
        return (score / 50 * 100)
    }

    const reverseFromPercentage = (score) => {
        return (score / 100) * 50
    }

    const correctScoreOrder = (scores) => {
        if(!scores.length) return []

        //Fixes array filled with NaN when certain participant types not involved with survey
        if(scores.includes(NaN)) return []

        let scores_left = scores.slice(0, 10)
        let scores_right = scores.slice(10, scores.length)
        return [...scores_right, ...scores_left]
    }

    const formatData = () => {
        let tm_scores = []
        let tl_scores = []
        let st_scores = []
        let theme_question_count = 0
        let tm_theme_total = 0
        let tl_theme_total = 0
        let st_theme_total = 0

        for(const theme of themes) {
            for(const pillar of surveyData.pillars) {
                for(const question of pillar.all_question_scores) {
                    if(theme !== question.theme_name) continue;

                    let tm_avg = convertToPercentage(getAverageScore(question.scores.team_member_scores))
                    let tl_avg = convertToPercentage(getAverageScore(question.scores.team_leader_scores))
                    let st_avg = convertToPercentage(getAverageScore(question.scores.stakeholder_scores))

                    tm_theme_total += tm_avg
                    tl_theme_total += tl_avg
                    st_theme_total += st_avg

                    theme_question_count += 1
                }
            }
            tm_scores.push(tm_theme_total / theme_question_count)
            tl_scores.push(tl_theme_total / theme_question_count)
            st_scores.push(st_theme_total / theme_question_count)

            tm_theme_total = 0
            tl_theme_total = 0
            st_theme_total = 0
            theme_question_count = 0
        }

        setTmData(correctScoreOrder(tm_scores))
        setTlData(correctScoreOrder(tl_scores))
        setStData(correctScoreOrder(st_scores))
    }

    const getCircumferenceX = (angle, radius) => {
        return radius * Math.cos(angle)
    }

    const getCircumferenceY = (angle, radius) => {
        return radius * Math.sin(angle)
    }

    const calculateScoreCoordinates = (points) => {
        if(!points.length) return;
        let coords = ""
        let cx = 240
        let cy = 240
        let r = 215
        let angle = (2 * Math.PI) / points.length

        let i = 0
        for (const point of points) {
            let px = (getCircumferenceX(angle * i, r) * (point / 100)) + cx
            let py = (getCircumferenceY(angle * i, r) * (point / 100)) + cy
            coords = coords + `${px},${py} `
            i += 1
        }

        return coords.slice(0, -1)
    }

    const calculatePointMarkers = (points) => {
        if(!points.length) return;
        let coords = []
        let cx = 240
        let cy = 240
        let r = 215
        let angle = (2 * Math.PI) / points.length

        let i = 0
        for (const point of points) {
            let px = (getCircumferenceX(angle * i, r) * (point / 100)) + cx
            let py = (getCircumferenceY(angle * i, r) * (point / 100)) + cy
            coords.push([px, py])
            i += 1
        }

        return coords
    }

    const calculateLineCoords = () => {
        let coords = []
        let cx = 240
        let cy = 240
        let r = 215
        let lines = 30
        let angle = (2 * Math.PI) / lines

        for (let i = 0; i<lines; i++) {
            let px = (getCircumferenceX(angle * i, r)) + cx
            let py = (getCircumferenceY(angle * i, r)) + cy
            coords.push([px, py])
        }

        return coords
    }

    const calculatePointCoords = (idx) => {
        let cx = 240
        let cy = 240
        let r = 180
        let angle = (2 * Math.PI) / 30

        let px = (getCircumferenceX((angle * idx) + (angle / 2), r)) + cx
        let py = (getCircumferenceY((angle * idx) + (angle / 2), r)) + cy
        return [px, py]
    }

    const generateInnerHoverSections = () => {
        let path = ""
        let paths = []
        let cx = 240
        let cy = 240
        let r = 215
        let sections = 30
        let angle = (2 * Math.PI) / sections

        for (let i = 0; i<sections; i++) {
            let j = i + 1
            if(i === 29) j = 0;

            let x1 = (getCircumferenceX(angle * i, r)) + cx
            let y1 = (getCircumferenceY(angle * i, r)) + cy
            let x2 = (getCircumferenceX(angle * j, r)) + cx
            let y2 = (getCircumferenceY(angle * j, r)) + cy

            path = `M ${cx},${cy} L ${x1},${y1} A ${cx},${cy} 12 0,1 ${x2},${y2} L ${cx},${cy}`
            paths.push(path)
        }

        return paths
    }

    const generateOuterHoverSections = () => {
        let path = ""
        let paths = []
        let cx = 240
        let cy = 240
        let or = 240
        let ir = 320
        let sections = 6
        let angle = (2 * Math.PI) / sections

        for (let i = 0; i<sections; i++) {
            let j = i + 1
            if(i === 29) j = 0;

            let ox1 = (getCircumferenceX(angle * i, or)) + cx
            let oy1 = (getCircumferenceY(angle * i, or)) + cy
            let ox2 = (getCircumferenceX(angle * j, or)) + cx
            let oy2 = (getCircumferenceY(angle * j, or)) + cy

            let ix1 = (getCircumferenceX(angle * i, ir)) + cx
            let iy1 = (getCircumferenceY(angle * i, ir)) + cy
            let ix2 = (getCircumferenceX(angle * j, ir)) + cx
            let iy2 = (getCircumferenceY(angle * j, ir)) + cy

            path = `M ${ox1},${oy1} A ${cx},${cy} 60 0,1 ${ox2},${oy2} M ${ox1},${oy1} L ${ix1},${iy1} A ${cx},${cy} 60 0,1 ${ix2},${iy2} L ${ox2},${oy2}`
            paths.push(path)
        }

        return paths
    }

    const getPointFill = (role) => {
        if (role === "tm") return "#27FF00";
        if (role === "tl") return "#FF00D8";
        if (role === "sh") return "#000AFF";
    }

    const getSectionName = (idx) => {
        let sections = [
            "Skills & Expertise", "Respect & Trust", "Camaraderie & Cohesion", "Safe Communication",
            "Values Diversity & Inclusion", "Appropriate Resourcing", "Team Agility", "Accountability",
            "System Efficacy", "Team Protection", "Development Planning", "Learning Relevance", "Embedded Learning",
            "Developmental Orientation", "Reflective Learning", "Vision & Context",
            "Collaborative & Empowering Leadership", "Interpersonal Leadership", "Leadership Courage",
            "Inspirational Leadership", "Alignment", "Clarity", "Enthusiasm & Contribution", "Enjoyment",
            "Support & Collaboration", "Environmental Awareness", "Stakeholder & Customer Alignment",
            "Talent & Resource Acquisition", "Stakeholder & Customer Communication",
            "Trust & Integrity in Relationships"
        ]
        return sections[idx]
    }

    const handleSectionMouseOver = (ev, idx) => {
        if(!ev) return
        setActiveHoverEvent(ev)
        setActiveHoverIdx(idx)
        let idx2;
        if(idx === 29) {
            idx2 = 0
        } else {
            idx2 = idx + 1
        }

        let elem = document.getElementById(`hover-${idx}-${props.chartId}`)
        let line1 = document.getElementById(`line-${idx}-${props.chartId}`)
        let line2 = document.getElementById(`line-${idx2}-${props.chartId}`)
        elem.style.filter = "url(#segment-drop-shadow)"
        line1.style.display = "none"
        line2.style.display = "none"

        let tm_score = reverseFromPercentage(tmData[idx])
        let tl_score = reverseFromPercentage(tlData[idx])
        let st_score = reverseFromPercentage(stData[idx])
        let active_scores = {
            "tm": tm_score,
            "tl": tl_score,
            "st": st_score
        }

        let point_coords = calculatePointCoords(idx)
        let yOffset = 15

        setHoverX(point_coords[0] - 17)
        setHoverY(point_coords[1] - yOffset)
        setActiveHoverScores(active_scores)
        setActiveHoverSection(getSectionName(idx))
    }

    const handleSectionMouseLeave = (ev, idx) => {
        if(!ev || mouseOverPopup) return
        setActiveHoverEvent(null)
        setActiveHoverIdx(0)
        let idx2;
        if(idx === 29) {
            idx2 = 0
        } else {
            idx2 = idx + 1
        }
        let elem = document.getElementById(ev.target.id)
        let line1 = document.getElementById(`line-${idx}-${props.chartId}`)
        let line2 = document.getElementById(`line-${idx2}-${props.chartId}`)
        elem.style.filter = "unset"
        line1.style.display = "unset"
        line2.style.display = "unset"

        setActiveHoverSection("")
        let active_scores = {
            "tm": 0,
            "tl": 0,
            "st": 0
        }
        setActiveHoverScores(active_scores)
        setHoverX(0)
        setHoverY(0)
    }

    const handleOuterSectionClick = (ev, idx) => {
        if (!ev) return;
        let true_idx = 0
        switch (idx) {
            case 0: true_idx = 2; break;
            case 1: true_idx = 3; break;
            case 2: true_idx = 4; break;
            case 3: true_idx = 5; break;
            case 4: true_idx = 0; break;
            case 5: true_idx = 1; break;
        }
        props.showPillars(true_idx)
    }

    return (
        <div className="summary-radial-chart-container">
            <svg height="480" width="480" xmlns="http://www.w3.org/2000/svg">
                <defs>
                    <filter id="inner-circle-box-shadow" width="150" height="150">
                        <feOffset in="SourceAlpha" dx="0" dy="0"/>
                        <feGaussianBlur stdDeviation="2"/>
                        <feBlend in="SourceGraphic" in2="blurOut"/>
                    </filter>
                    <radialGradient id="pale-filter-inset-shadow" cx="50%" cy="50%" r="100%" fx="50%" fy="50%">
                        <stop offset="45%" stopColor="rgba(255, 255, 255, 0.5"/>
                        <stop offset="56%" stopColor="rgba(0, 0, 0, 1)"/>
                    </radialGradient>
                    <filter id="segment-drop-shadow">
                        <feColorMatrix type="matrix" values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 100 0"
                                       result="boostedInput"/>
                        <feDropShadow dx="0" dy="0" stdDeviation="3" floodColor="rgba(0, 0, 0, 0.8)"/>
                        <feComposite operator="out" in2="boostedInput"/>
                    </filter>
                    <filter id="trans-shadow">

                    </filter>

                    <path id="segment-text" d="M 240,240 m 223,0 a 223,223 60 0,1 -111.5,193.124"/>
                    <path id="segment-text-reversed" d="M 240,240 m -233,0 a 233,233 60 0,0 116.5,201.784"/>
                </defs>

                {/*Styling*/}
                <circle cx="240" cy="240" r="215" fill="url(#pale-filter-inset-shadow)"/>
                {calculateLineCoords().map((line, idx) => {
                    return <line x1="240" y1="240" x2={line[0]} y2={line[1]} id={`line-${idx}-${props.chartId}`}
                                 style={{stroke: "rgba(255, 255, 255, 0.5)", strokeWidth: "1"}} key={idx}/>
                })}

                {/*Team Member Graph*/}
                {props.tmEnabled && tmData.length ?
                    <>
                        <polygon points={calculateScoreCoordinates(tmData)} transform="rotate(6, 240, 240)"
                                 style={{fill: "rgba(255, 255, 255, 0.5)", stroke: "#27FF00", strokeWidth: "3"}}/>
                        {calculatePointMarkers(tmData).map((point, idx) => {
                            return <circle cx={point[0]} cy={point[1]} r="4" stroke="#27FF00" fill={getPointFill("tm")}
                                           transform="rotate(6, 240, 240)" style={{strokeWidth: "1"}} key={idx}/>
                        })}
                    </> : null
                }

                {/*Team Leader Graph*/}
                {props.tlEnabled && tlData.length ?
                    <>
                        <polygon points={calculateScoreCoordinates(tlData)} transform="rotate(6, 240, 240)"
                                 style={{fill: "rgba(255, 255, 255, 0.5)", stroke: "#FF00D8", strokeWidth: "3"}}/>
                        {calculatePointMarkers(tlData).map((point, idx) => {
                            return <circle cx={point[0]} cy={point[1]} r="4" stroke="#FF00D8" fill={getPointFill("tl")}
                                           transform="rotate(6, 240, 240)" style={{strokeWidth: "1"}} key={idx}/>
                        })}
                    </> : null
                }
                
                {/*Stakeholder Graph*/}
                {props.stEnabled && stData.length ?
                    <>
                        <polygon points={calculateScoreCoordinates(stData)} transform="rotate(6, 240, 240)"
                                 style={{fill: "rgba(255, 255, 255, 0.5)", stroke: "#000AFF", strokeWidth: "3"}}/>
                        {calculatePointMarkers(stData).map((point, idx) => {
                            return <circle cx={point[0]} cy={point[1]} r="4" stroke="#000AFF" fill={getPointFill("sh")}
                                           transform="rotate(6, 240, 240)" style={{strokeWidth: "1"}} key={idx}/>
                        })}
                    </> : null
                }
                
                {/*Large section text*/}
                <text style={{fill: "white", fontSize: 15, userSelect: "none", msUserSelect: "none"}}
                      transform="rotate(-120, 240, 240)">
                    <textPath href="#segment-text-reversed" lengthAdjust="spacing" textAnchor="middle" startOffset="50%"
                              textLength={((2 * Math.PI * 225) / 6) * 0.43} method="stretch">
                        Relationships
                    </textPath>
                </text>
                <text style={{fill: "white", fontSize: 15, userSelect: "none", msUserSelect: "none"}}
                      transform="rotate(-60, 240, 240)">
                    <textPath href="#segment-text-reversed" lengthAdjust="spacing" textAnchor="middle" startOffset="50%"
                              textLength={((2 * Math.PI * 225) / 6) * 0.84} method="stretch">
                        Internal Systems & Processes
                    </textPath>
                </text>
                <text style={{fill: "white", fontSize: 15, userSelect: "none", msUserSelect: "none"}}>
                    <textPath href="#segment-text-reversed" lengthAdjust="spacing" textAnchor="middle" startOffset="50%"
                              textLength={((2 * Math.PI * 225) / 6) * 0.29} method="stretch">
                        Learning
                    </textPath>
                </text>
                <text style={{fill: "white", fontSize: 15, userSelect: "none", msUserSelect: "none"}}
                      transform="rotate(180, 240, 240)">
                    <textPath href="#segment-text" lengthAdjust="spacing" textAnchor="middle" startOffset="50%"
                              textLength={((2 * Math.PI * 225) / 6) * 0.32} method="stretch">
                        Leadership
                    </textPath>
                </text>
                <text style={{fill: "white", fontSize: 15, userSelect: "none", msUserSelect: "none"}}
                      transform="rotate(240, 240, 240)">
                    <textPath href="#segment-text" lengthAdjust="spacing" textAnchor="middle" startOffset="50%"
                              textLength={((2 * Math.PI * 225) / 6) * 0.61} method="stretch">
                        Purpose & Motivation
                    </textPath>
                </text>
                <text style={{fill: "white", fontSize: 15, userSelect: "none", msUserSelect: "none"}}
                      transform="rotate(300, 240, 240)">
                    <textPath href="#segment-text" lengthAdjust="spacing" textAnchor="middle" startOffset="50%"
                              textLength={((2 * Math.PI * 225) / 6) * 0.85} method="stretch">
                        External Systems & Processes
                    </textPath>
                </text>
                
                {/*Interactive elements*/}
                {generateOuterHoverSections().map((section, idx) => {
                    return <path d={section} style={{fill: "transparent", stroke: "none", cursor: "pointer"}}
                                 onClick={ev => handleOuterSectionClick(ev, idx)} key={idx}
                    />
                })}
                {generateInnerHoverSections().map((section, idx) => {
                    return <path d={section} id={`hover-${idx}-${props.chartId}`} key={idx}
                                 style={{fill: "black", stroke: "none", cursor: "pointer", fillOpacity: "0.01"}}
                                 onMouseOver={ev => handleSectionMouseOver(ev, idx)}
                                 onMouseLeave={ev => handleSectionMouseLeave(ev, idx)}
                                 />
                })}

                {/*Inner white circle*/}
                <circle cx="240" cy="240" r="25" fill="white" filter="url(#inner-circle-box-shadow)"/>
            </svg>
            {activeHoverSection ?
                <div className="summary-chart-section-hover-container" id="hover-popup-container"
                     style={{left: hoverX, top: hoverY}}
                     onMouseOver={() => handleSectionMouseOver(activeHoverEvent, activeHoverIdx)}
                     onMouseLeave={() => handleSectionMouseLeave(activeHoverEvent, activeHoverIdx)}>
                    <div className="summary-chart-section-hover-box">
                        <div className="summary-chart-section-hover-box-section thin">
                            <div className="summary-chart-section-hover-theme-container">
                                <div className="summary-chart-section-hover-theme-name">{activeHoverSection}</div>
                            </div>
                        </div>
                    </div>
                    <div className="summary-chart-section-hover-triangle thin">
                        <div className="summary-chart-section-hover-triangle-inner thin"/>
                    </div>
                </div> : null
            }
        </div>
    )
}

export default ComparisonSummaryChart