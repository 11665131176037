import {useEffect, useState} from "react";


const ComparisonSurveyCard = (props) => {
    const survey = props.survey
    const idx = props.idx

    const [postition, setPosition] = useState("start")

    useEffect(() => {
        if (!idx) return;
        setPosition("end")
    }, [idx]);

    return(
        <div className={`comparison-survey-card-container ${postition}`}>
            <div className="comparison-survey-card-header-container">
                <div className="comparison-survey-card-header">Survey type</div>
                <div className="comparison-survey-card-header">Created</div>
            </div>
            <div className="comparison-survey-card-row">
                <div className="survey-summary-details-light-switch-container">
                    <div className="survey-summary-details-light-switch">
                        <div className={`light-switch ${survey && survey.survey_type !== 1 ? "active" : "inactive"}`}/>
                        <div className="light-switch-text">Lite</div>
                    </div>
                    <div className="survey-summary-details-light-switch">
                        <div className={`light-switch ${survey && survey.survey_type === 1 ? "active" : "inactive"}`}/>
                        <div className="light-switch-text">Full</div>
                    </div>
                </div>
                <div className="comparison-survey-card-date">
                    {new Date(survey.survey_created_date * 1000).toLocaleDateString("en-UK")}
                </div>
            </div>
            <div className="comparison-survey-card-header-container">
                <div className="comparison-survey-card-header">Survey point</div>
            </div>
            <div className="comparison-survey-card-row">
                <div className="survey-summary-details-light-switch-container">
                    <div className="survey-summary-details-light-switch">
                        <div className={`light-switch ${survey && survey.survey_point === 1 ? "active" : "inactive"}`}/>
                        <div className="light-switch-text">Initial</div>
                    </div>
                    <div className="survey-summary-details-light-switch">
                        <div className={`light-switch ${survey && survey.survey_point === 2 ? "active" : "inactive"}`}/>
                        <div className="light-switch-text">Midpoint</div>
                    </div>
                    <div className="survey-summary-details-light-switch">
                        <div className={`light-switch ${survey && survey.survey_point === 3 ? "active" : "inactive"}`}/>
                        <div className="light-switch-text">Endpoint</div>
                    </div>
                    <div className="survey-summary-details-light-switch">
                        <div className={`light-switch ${survey && survey.survey_point === 4 ? "active" : "inactive"}`}/>
                        <div className="light-switch-text">Post Engagement</div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ComparisonSurveyCard