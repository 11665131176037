import info from "../assets/teams-info.svg"
import tick from "../assets/green-tick-teams.svg"
import summary from "../assets/summary-report-icon.svg"
import teams_api from "../api/protected/teams"
import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";

const EditTeamPreviousSurveysContainer = (props) => {

    const navigate = useNavigate()
    const [surveys, setSurveys] = useState([])

    useEffect(() => {
        if(!props.team_id) return;
        getSurveys()
    }, [props.team_id]);

    const getSurveys = () => {
        let response = teams_api.getPreviousSurveys(props.team_id, props.isDemo)
        response.then(response => {
            setSurveys(response.data)
        }).catch(err => console.log(err))
    }

    const getSurveyPoint = (point) => {
        switch (point) {
            case 1: return "Initial";
            case 2: return "Midpoint";
            case 3: return "Endpoint";
            case 4: return "Post Review";
        }
    }

    const formatDate = (timestamp) => {
        let current_date = new Date(timestamp * 1000);
        return `${current_date.getDate()}/${current_date.getMonth() + 1}/${current_date.getFullYear()}`
    }

    const handleSummaryClick = (ev, id) => {
        navigate(`/surveys/${id}?overview=true`)
    }


    return (
        <div className="edit-team-previous-surveys-container">
            <div className="edit-team-details-top-row aligned">
                <div className="edit-team-details-title bold">Previous Surveys</div>
                <div className="edit-team-previous-surveys-info">
                    <img src={info} alt="Info"/>
                </div>
            </div>
            <div className="edit-team-previous-surveys-headings medium">
                <div className="previous-survey-heading">Survey Type</div>
                <div className="previous-survey-heading">Survey Point</div>
                <div className="previous-survey-heading centred">Participants</div>
                <div className="previous-survey-heading">Issue Date</div>
                <div className="previous-survey-heading">Expiry Date</div>
                <div className="previous-survey-heading centred">Survey Completion</div>
                <div className="previous-survey-heading centred">Survey Report</div>
            </div>
            {surveys.map((survey, idx) => {
                console.log(survey)
                return (
                    <div className="edit-team-previous-survey-row" key={idx}>
                        <div className="previous-survey-item short bold">{survey.type === 1 ? "Full" : "Lite"}</div>
                        <div className="previous-survey-item regular">{getSurveyPoint(survey.point)}</div>
                        <div className="previous-survey-item centred regular">{survey.participant_count}</div>
                        <div className="previous-survey-item regular">{formatDate(survey.created_date)}</div>
                        <div className="previous-survey-item regular">{formatDate(survey.expiry_date)}</div>
                        <div className="previous-survey-item icon-container">
                            <div className="previous-survey-subitem bold">{survey.completion_avg}%</div>
                            <div className="previous-survey-subitem-img">
                                {survey.completion_avg === 100 ? <img src={tick} alt=""/> : null}
                            </div>
                        </div>
                        <div className="previous-survey-item short centred" onClick={ev => handleSummaryClick(ev, survey.id)}>
                            <img src={summary} alt="Run summary" className="clickable"/>
                        </div>
                    </div>
                )
            })}
        </div>
    )
}


export default EditTeamPreviousSurveysContainer