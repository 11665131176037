import {useEffect, useState} from "react";


const SummaryGraphicFlexedPoint = (props) => {
    const point = props.point;
    const data = props.data;
    const attributableData = props.attributableData;
    const idx = props.idx;
    const [showPopup, setShowPopup] = useState(false)
    const [name, setName] = useState("Team")

    const handlePopup = (boolean) => {
        setShowPopup(boolean)
    }

    const leftValue = (index, data) => {
        if (!data) return "28px"
        let _start = 112
        let _data = [...data]
        switch (_data.length) {
            case 2:
                _start = 84
                break
            case 3:
                _start = 56
                break
            case 4:
                _start = 28
                break
            case 5:
                _start = 0
                break
        }
        let _value = _start + (index * 28)
        return `${_value}px`
    }

    useEffect(() => {
        if (point.name) setName(point.name)
        if (point.team_name) setName(point.team_name)
        console.log(point)
    },[point])

    return(
        <>
            <div className={`summary-graphical-point-flexed ${point.class}`}
                 key={idx} onMouseOver={() => handlePopup(true)} onMouseOut={() => handlePopup(false)}>
                <div className="summary-graphical-point-centre" style={{backgroundColor: point.colour}}
                     onMouseOver={() => handlePopup(true)}/>
            </div>
            {showPopup ?
                <div className="summary-graphical-point-hover-container" style={{left: leftValue(idx, data)}}>
                    <div className="summary-graphical-point-hover-triangle">
                        <div className="summary-graphical-point-hover-triangle-inner"/>
                    </div>
                    <div className="summary-graphical-point-hover-main">
                        <div className="summary-graphical-average-hover-details">
                            <div className="hover-details-key">Score</div>
                            <div className="hover-details-score">
                                {point.score}
                            </div>
                        </div>
                        <div className={`summary-graphical-point-hover-circle ${point.class}`} style={{backgroundColor: point.colour}}/>
                        <div className="summary-graphical-point-hover-text">
                            {attributableData ? name : point.hidden_name}
                        </div>
                    </div>
                </div>
                :null}
        </>

    )
}

export default SummaryGraphicFlexedPoint