import {useEffect, useState} from "react";
import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import SurveyParticipantsGridContainer from "../containers/SurveyParticipantsGridContainer";
import SurveyOverviewParticipantSelector from "../components/SurveyOverviewParticipantSelector";
import SurveyOverviewResendSelector from "../components/SurveyOverviewResendSelector";
import SurveyUpdateParticipants from "../components/SurveyUpdateParticipants"
import GenericDialog from "../components/GenericDialog";
import DeleteDialog from "../components/DeleteDialog";
import Slider from "../components/Slider";
import surveys_api from "../api/protected/surveys";
import people_api from "../api/protected/people";
import DatePicker from "react-datepicker";
import types from "../assets/constants/types";
import overviewIcon from "../assets/overview.svg";
import deleteIcon from "../assets/delete-icon.svg";
import back_arrow from "../assets/back_arrow.png";


const EditSurvey = () => {
    const params = useParams();
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();

    const [surveyId, setSurveyId] = useState()
    const [isDemo, setIsDemo] = useState(false)

    const [selectedPeople, setSelectedPeople] = useState([]);
    const [survey, setSurvey] = useState();
    const [people, setPeople] = useState();
    const [totalCompleted, setTotalCompleted] = useState(2);
    const [editor, setEditor] = useState(false);

    const [showDelete, setShowDelete] = useState();
    const buttons = [{"text":"Delete", "id": "1"}, {"text":"Cancel", "id": "2"}];

    const [showDeleteHover, setShowDeleteHover] = useState(false)
    const [showOverviewHover, setShowOverviewHover] = useState(false)
    const [showHidden, setShowHidden] = useState(false)

    const [showParticipantSelection, setShowParticipantSelection] = useState(false)
    const [showInsufficientResponses, setShowInsufficientResponses] = useState(false)

    const [newlySelected, setNewlySelected] = useState([])
    const [showPopup, setShowPopup] = useState(false)

    const [showResendPopup, setShowResendPopup] = useState(false)
    const [showResendConfirmation, setShowResendConfirmation] = useState(false)
    const [resendPopupTitle, setResendPopupTitle] = useState("")
    const [resendPopupText, setResendPopupText] = useState("")


    useEffect(() => {
        if(searchParams.get('demo')) {
            setIsDemo(true)
        }
        setSurveyId(params.surveyId)
    }, []);

    useEffect(() => {
        if(!survey) return;
        let response = surveys_api.putSurveyVisibility(surveyId, {"hidden": showHidden})
        response.then(() => {}).catch(err => console.log(err))
    }, [survey, showHidden])

    const disabled_date = (surveyDate) => new Date() < surveyDate;

    const GetSurvey = () => {
        let response;
        if(isDemo) {
            response = surveys_api.getDemoSurvey(surveyId);
        } else {
            response = surveys_api.getSurvey(surveyId);
        }
        response.then(response => {
            setSurvey(response.data.surveys[0]);
            setEditor(response.data.surveys[0].editor)
            setSelectedPeople(response.data.participants);
            setTotalCompleted(response.data.participants.filter((p) => p.percentage_complete === 100).length);
            getPeople(response.data.surveys[0].team_id)
        })
    }

    const getPeople = (team_id) => {
        let response;
        if(isDemo) {
            response = people_api.getDemoPeople(team_id);
        } else {
            response = people_api.getPeople(team_id);
        }
        response.then(response => {
            setPeople(response.data.people);
        })
    }

    const handleDeleteClick = () => {
        setShowDelete(!showDelete)
    }

    const handleOverviewClick = () => {
        if (totalCompleted < 2) {
            handleInsufficientPopup()
        } else {
            setShowParticipantSelection(true)
        }
    }

    const handleDeleteResult = (result) => {
        if (result === "1") deleteSurvey();
        setShowDelete(false)
    }

    const handleNewlySelected = (result) => {
        if (!result) return;
        if (result === "1") updateSurvey();
        setShowPopup(false)
    }

    const deleteSurvey = () => {
        let response = surveys_api.deleteSurvey(surveyId);
        response.then(() => navigate("/surveys")).catch(() => console.log("Failed to delete survey"))
    }

    const handleDeleteHoverOn = () => {
        setShowDeleteHover(true)
    }

    const handleDeleteHoverOut = () => {
        setShowDeleteHover(false)
    }

    const handleClickHoverOn = () => {
        setShowOverviewHover(true)
    }

    const handleClickHoverOut = () => {
        setShowOverviewHover(false)
    }

    const submitController = () => {
        if (newlySelected.length === 0) {
            updateSurvey();
        } else {
            setShowPopup(true)
        }
    }

    const updateSurvey = () => {
        let body = {
            "team_id": survey.team_id,
            "name": survey.survey_name,
            "expiry_date": survey.expiry_date,
            "participants": selectedPeople
        }

        let response = surveys_api.updateSurvey(survey.id, body);
        response.then(() => navigate("/surveys")).catch(() => console.log(body))
    }

    const updateToggleHidden = () => {
        setShowHidden(!showHidden);
    }

    const handleFooter = (result) => {
        if (!result) return;
        if (result.button === "1") {
            if (result.people.length > 1) {
                let selectedURL = "["
                for (let i = 0; i < result.people.length; i++) {
                    let participant_id = result.people[i].id
                    selectedURL += participant_id + ","
                }
                selectedURL = selectedURL.substring(0, selectedURL.length - 1) + "]";
                if(isDemo) {
                    navigate(`/diagnostic_summary/${surveyId}?selected=${selectedURL}&demo=true`);
                } else {
                    navigate(`/diagnostic_summary/${surveyId}?selected=${selectedURL}`);
                }
            } else alert("Please select two or more participants.");
        } else setShowParticipantSelection(!showParticipantSelection);
    }

    const handleInsufficientPopup = () => {
        setShowInsufficientResponses(!showInsufficientResponses)
        setShowParticipantSelection(false)
    }

    const getSurveyName = () => {
        if (survey.survey_name) return survey.survey_name
        return "Edit Survey"
    }

    const resendEmails = (people) => {
        let ppl = []
        for (const person of people) ppl.push(person.person_id);

        let response = surveys_api.resendEmails(surveyId, ppl)
        response.then(() => {
            setResendPopupTitle("Emails Resent")
            setResendPopupText("The emails have successfully been resent!")
            setShowResendPopup(true)
        }).catch(() => {
            setResendPopupTitle("Emails Failed")
            setResendPopupText("There has been an error trying to resend the emails. Please try again")
            setShowResendPopup(true)
        })
    }

    const handleResendClick = (ev) => {
        if(!ev) return;
        setShowResendConfirmation(true)
    }

    const handleResendConfirmation = (res) => {
        console.log(res)
        setShowResendConfirmation(false)
        if(res.button === "1") {
            resendEmails(res.people)
        }
    }

    useEffect(() => {
        if (!surveyId) return;
        GetSurvey()
    }, [surveyId]);

    useEffect(() => {
        if (!survey) return;
        if (survey.hidden === 0) {
            setShowHidden(false)
        } else {
            setShowHidden(true)
        }
    }, [survey]);

    useEffect(() => {
        if (searchParams.get("overview")) {
            setSearchParams(searchParams)
            if (totalCompleted < 2) {
                handleInsufficientPopup()
            } else {
                setShowParticipantSelection(true)
            }
        }
    },[totalCompleted])

    const handleResendPopup = () => {
        setShowResendPopup(false)
        setResendPopupTitle("")
        setResendPopupText("")
    }

    return (
        <div className="surveys-page-background-container">
            <div className="surveys-page-container">
                <div className="back-link link" onClick={() => navigate("/surveys")}>
                    <img src={back_arrow} alt={"Back Arrow"}/>
                </div>
                {survey && survey ?
                    <>
                        <div className="surveys-page-header">
                            <div className="surveys-page-title">
                                {getSurveyName()} {survey.draft ? <span className="survey-page-draft">(draft)</span> : null}
                            </div>
                            <div className="col">
                                <div className="header-icon-container">
                                    {editor ?
                                        <>
                                            <div onClick={handleResendClick}
                                                 className={"header-resend-emails-text"}>Resend Emails
                                            </div>
                                            <div className="header-slider-container">
                                                <Slider update={updateToggleHidden.bind(this)} state={showHidden} type="Survey"/>
                                                <div className="header-hide-text">Hide</div>
                                            </div>
                                        </> : null
                                    }
                                    <img className="header-overview-icon link" src={overviewIcon} alt="overview icon"
                                         onClick={handleOverviewClick} onMouseOver={handleClickHoverOn}
                                         onMouseLeave={handleClickHoverOut}/>
                                    {showOverviewHover ?
                                        <div className="delete-tooltip-container overview">
                                            <div className="delete-tooltip-text">Diagnostic Summary</div>
                                        </div> : null
                                    }
                                    {editor ?
                                        <div className="header-delete-container" onMouseOver={handleDeleteHoverOn}
                                             onClick={handleDeleteClick} onMouseLeave={handleDeleteHoverOut}>
                                            <img className="header-delete-icon link" src={deleteIcon} alt="Delete Survey"/>
                                            {showDeleteHover ?
                                                <div className="delete-tooltip-container">
                                                    <div className="delete-tooltip-text">Delete Survey</div>
                                                </div> : null
                                            }
                                        </div> : null
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="survey-form-container">
                            <div className="survey-form-title">Survey Details</div>
                            <div className="field-input">
                                <div className="field-input-entry">
                                    <div className="label">Survey Type</div>
                                    <input className="survey-type-input" type="text" disabled={true}
                                           value={survey && types.survey_types.find(x => x.id === survey.survey_type).name}
                                    />
                                </div>
                                <div className="field-input-entry">
                                    <div className="label">Survey Point</div>
                                    <input className="survey-point-input" type="text" disabled={true}
                                           value={survey && types.point_types.find(x => x.id === survey.survey_point).name}
                                    />
                                </div>
                            </div>
                            <div className="field-input">
                                <div className="field-input-entry">
                                    <div className="label">Company</div>
                                    <input className="survey-company-input" type="text" disabled={true}
                                           value={survey && survey.company_name}
                                    />
                                </div>
                                <div className="field-input-entry">
                                    <div className="label">Team</div>
                                    <input className="survey-team-input" type="text" disabled={true}
                                           value={survey && survey.team_name}
                                    />
                                </div>
                            </div>
                            <div className="field-input">
                                <div className="label">Survey Name</div>
                                <div className="field-input-entry">
                                    <input className="survey-name-input" type="text" disabled={!editor} required
                                           value={survey && survey.survey_name}
                                           onChange={(ev) => {
                                               let s = {...survey};
                                               s.survey_name = ev.target.value;
                                               setSurvey(s);
                                           }}
                                    />
                                </div>
                            </div>
                            <div className="field-input">
                                <div className="field-input-entry">
                                    <div className="label">Expiry Date</div>
                                    <DatePicker dateFormat="dd/MM/yyyy" locale="en-gb" filterDate={disabled_date}
                                                selected={survey && survey.expiry_date * 1000}
                                                onChange={(date) => {
                                                    let s = {...survey};
                                                    s.expiry_date = date / 1000;
                                                    setSurvey(s);
                                                }}
                                                disabled={!editor}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="survey-form-container">
                            <div className="survey-form-title">Participants</div>
                            <SurveyParticipantsGridContainer people={people} selectedPeople={selectedPeople}
                                                             updateSelected={setSelectedPeople.bind(this)}
                                                             newlySelected={newlySelected}
                                                             updateNewlySelected={setNewlySelected.bind(this)}
                                                             editor={editor}
                                                             isDemo={isDemo}
                            />
                            {editor ?
                                <div className="field-input">
                                    <button className={`update-company-button link ${(!survey || !survey.survey_name) ? "disabled" : ""}`}
                                            disabled={!survey || !survey.survey_name || !editor}
                                            onClick={submitController}>
                                        Update
                                    </button>
                                </div> : null
                            }
                        </div>
                    </> : null
                }
                {showDelete ?
                    <DeleteDialog title="Delete Survey" type="Survey" name={survey.survey_name && survey.survey_name}
                                  description={survey.survey_description && survey.survey_description}
                                  buttons={buttons} result={handleDeleteResult.bind(this)}/>
                    : null
                }
                {showParticipantSelection ?
                    <SurveyOverviewParticipantSelector people={selectedPeople} result={handleFooter.bind(this)}
                    /> : null
                }
                {showPopup ?
                    <SurveyUpdateParticipants people={newlySelected} result={handleNewlySelected.bind(this)}
                                              cancel_text="Cancel"
                    /> : null
                }
                {showInsufficientResponses ?
                    <GenericDialog title="Diagnostic Summary"
                                   text="Insufficient responses have been received, two or more completed responses needed."
                                   buttons={[{"text":"OK", "id": "1"}]} result={handleInsufficientPopup.bind(this)}
                    /> : null
                }
                {showResendPopup ?
                    <GenericDialog title={resendPopupTitle} text={resendPopupText}
                                   buttons={[{"text":"OK", "id": "1"}]} result={handleResendPopup.bind(this)}
                    /> : null
                }
                {showResendConfirmation ?
                    <SurveyOverviewResendSelector people={selectedPeople} result={handleResendConfirmation.bind(this)}
                    /> : null
                }
            </div>
        </div>
    )
}

export default EditSurvey;
