import tick from "../../assets/green-tick-teams.svg"
import run_summary from "../../assets/run-summary.svg"
import info from "../../assets/teams-info.svg"
import {useEffect, useState} from "react";
import teams_api from "../../api/protected/teams"
import {useNavigate} from "react-router-dom";


const TeamsCard = (props) => {

    const navigate = useNavigate()
    const [survey, setSurvey] = useState(null)

    useEffect(() => {
        if(!props.team_id) return;
        getLatestSurvey()
    }, [props.team_id]);

    const getLatestSurvey = () => {
        let response = teams_api.getLatestSurvey(props.team_id, false);
        response.then(response => {
            setSurvey(response.data)
            console.log(response.data)
        }).catch(err => console.log(err))
    }

    const formatDate = (timestamp) => {
        let current_date = new Date(timestamp * 1000);
        return `${current_date.getDate()}/${current_date.getMonth() + 1}/${current_date.getFullYear()}`
    }

    const handleScroll = (ev) => {
        let handleMax = 193
        let height = 256
        let scrollHeight = ev.target.scrollHeight - height
        let multiplier = 0

        if (ev.target.scrollTop) {
            multiplier = (ev.target.scrollTop / scrollHeight)
        }

        let elem = document.getElementById('scroll-handle')
        elem.style.top = `${Math.round(handleMax * multiplier)}px`
    }

    const handleSummaryClick = (ev) => {
        if(!ev) return;
        navigate(`/teams/${props.team_id}`)
    }


    return (
        <div className="dashboard-team-card-container">
            <div className="dashboard-team-card-top-row">
                <div className="dashboard-team-card-details">
                    <div className="dashboard-team-card-colour-circle" style={{backgroundColor: `#${survey?.colour}`}}/>
                    <div className="dashboard-team-card-company-name bold">{survey?.company_name}</div>
                    <div className="dashboard-team-card-team-name regular">{survey?.team_name}</div>
                </div>
                <div className="edit-team-latest-survey-run-summary-button dashboard" onClick={handleSummaryClick}>
                    <div className="edit-team-latest-survey-run-summary-text bold">SEE TEAM PAGE</div>
                    <div className="edit-team-latest-survey-run-summary-img">
                        <img src={run_summary} alt="SEE TEAM PAGE"/>
                    </div>
                </div>
            </div>
            <div className="dashboard-team-card-bottom-row">
                <div className="edit-team-latest-survey-left">
                    <div className="edit-team-latest-survey-title bold">Latest Survey</div>
                    <div className="edit-team-latest-survey-divider" style={{width: 346}}/>
                    <div className="edit-team-latest-survey-subtitle bold" style={{marginTop: 30}}>Survey Type</div>
                    <div className="edit-team-latest-survey-light-row">
                        <div className="latest-survey-light-container">
                            <div className={`latest-survey-light ${survey && survey.type === 2 ? "filled" : ""}`}/>
                            <div className="latest-survey-light-text">Lite</div>
                        </div>
                        <div className="latest-survey-light-container">
                            <div className={`latest-survey-light ${survey && survey.type === 1 ? "filled" : ""}`}/>
                            <div className="latest-survey-light-text">Full</div>
                        </div>
                    </div>
                    <div className="edit-team-latest-survey-subtitle bold">Survey Point</div>
                    <div className="edit-team-latest-survey-light-row">
                        <div className="latest-survey-light-container wide">
                            <div className={`latest-survey-light ${survey && survey.point === 1 ? "filled" : ""}`}/>
                            <div className="latest-survey-light-text">Initial</div>
                        </div>
                        <div className="latest-survey-light-container wide">
                            <div className={`latest-survey-light ${survey && survey.point === 2 ? "filled" : ""}`}/>
                            <div className="latest-survey-light-text">Midpoint</div>
                        </div>
                        <div className="latest-survey-light-container wide">
                            <div className={`latest-survey-light ${survey && survey.point === 3 ? "filled" : ""}`}/>
                            <div className="latest-survey-light-text">Endpoint</div>
                        </div>
                        <div className="latest-survey-light-container very-wide">
                            <div className={`latest-survey-light ${survey && survey.point === 4 ? "filled" : ""}`}/>
                            <div className="latest-survey-light-text">Post Engagement</div>
                        </div>
                    </div>
                    <div className="edit-team-latest-survey-divider" style={{width: 346, marginTop: 45}}/>
                    <div className="edit-team-latest-survey-key-value-container">
                        <div className="edit-team-latest-survey-subtitle bold">Number of Participants:</div>
                        <div className="edit-team-latest-survey-value bold">{survey && survey.participants.length}</div>
                    </div>
                    <div className="edit-team-latest-survey-divider" style={{width: 346, marginBottom: 15}}/>
                    <div className="edit-team-latest-survey-dates-container">
                        <div className="latest-survey-date">
                            <div className="edit-team-latest-survey-subtitle bold">Issue Date</div>
                            <div className="latest-survey-date-value regular">{survey && formatDate(survey.created_date)}</div>
                        </div>
                        <div className="latest-survey-date">
                            <div className="edit-team-latest-survey-subtitle bold">Expiry Date</div>
                            <div className="latest-survey-date-value regular">{survey && formatDate(survey.expiry_date)}</div>
                        </div>
                    </div>
                </div>
                <div className="edit-team-latest-survey-right">
                    <div className="edit-team-latest-survey-title bold">Survey Completion by Participants</div>
                    <div className="dashboard-teams-card-completion-box-container">
                        <div className="dashboard-teams-card-completion-box bold">{survey && survey.completion_avg}%</div>
                        <div className="dashboard-teams-card-completion-text regular">OVERALL COMPLETION</div>
                    </div>
                    <div className="edit-team-latest-survey-scroll-container">
                        <div className="edit-team-latest-survey-scroll-area" onScroll={handleScroll}>
                            {survey && survey.participants.map((participant, idx) => {
                                return (
                                    <div className="edit-team-latest-survey-scroll-row" key={idx}>
                                        <div className="edit-team-latest-survey-scroll-details">
                                            <div className="edit-team-latest-survey-scroll-details-name-container bold">
                                                <div className="scroll-row-text">{participant.name}</div>
                                                <div className="scroll-row-text">{participant.completion}%</div>
                                            </div>
                                            <div className="edit-team-latest-survey-progress-bar-container">
                                                <div className="edit-team-latest-survey-progress-bar-lower"/>
                                                <div className={`edit-team-latest-survey-progress-bar ${participant.completion === 100 ? "complete" : ""}`}
                                                     style={{width: `${participant.completion}%`}}/>
                                            </div>
                                        </div>
                                        <div className="edit-team-latest-survey-scroll-circle">
                                            {participant.completion === 100 ? <img src={tick} alt="Complete"/> : null}
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                        {survey && survey.participants.length >= 4 ?
                            <div className="edit-team-latest-survey-scroll-bar">
                                <div className="edit-team-latest-survey-scroll-handle" id="scroll-handle"/>
                            </div> : null
                        }
                    </div>
                </div>
                <div className="edit-team-latest-survey-info-icon dashboard">
                    <img src={info} alt="Info"/>
                </div>
            </div>
        </div>
    )
}


export default TeamsCard