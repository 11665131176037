import comparison from "../assets/comparison-report-icon.svg"
import {useEffect, useState} from "react";
import teams_api from "../api/protected/teams"


const EditTeamPerformanceContainer = (props) => {

    const [surveys, setSurveys] = useState([])
    const [usableSurveys, setUsableSurveys] = useState([])

    useEffect(() => {
        if(!props.team_id) return;
        getTeamPerformance()
    }, [props.team_id]);

    useEffect(() => {
        if(!surveys.length) return;
        formatSurveyData()
    }, [surveys])

    const getTeamPerformance = () => {
        let response = teams_api.getTeamImprovement(props.team_id, props.isDemo);
        response.then(response => {
            setSurveys(response.data)
        }).catch(err => console.log(err))
    }

    const formatSurveyData = () => {
        let usable = []
        for(const survey of surveys) {
            if(survey && survey.has_data) {
                survey.last = false
                usable.push(survey)
            }
        }

        if(usable.length) {
            let last = usable.pop()
            last.last = true
            usable.push(last)
        }

        let margin = 50
        let gap = 1000 / (usable.length + 1)

        for(let idx in usable) {
            usable[idx].x = (gap * (parseInt(idx) + 1)) + margin
        }
        setUsableSurveys(usable)
    }

    const formatDate = (timestamp) => {
        let current_date = new Date(timestamp * 1000);
        return `${current_date.getDate()}/${current_date.getMonth() + 1}/${current_date.getFullYear()}`
    }

    const calculatePointHeight = (score) => {
        let graph_height = 300
        let offset = 10
        let units = score * 6
        return graph_height - units + offset
    }

    const getLineEndpoint = (idx, type) => {
        switch (type) {
            case "lowest": return [usableSurveys[idx + 1].x, calculatePointHeight(usableSurveys[idx + 1].lowest)]
            case "highest": return [usableSurveys[idx + 1].x, calculatePointHeight(usableSurveys[idx + 1].highest)]
            case "avg": return [usableSurveys[idx + 1].x, calculatePointHeight(usableSurveys[idx + 1].avg)]
        }
    }


    return (
        <div className="edit-team-performance-container">
            <div className="edit-team-performance-title bold">Team Performance Over Time</div>
            <div className="edit-team-performance-description-container">
                <div className="edit-team-performance-description regular">
                    Average score and variance trends over period of coaching engagement.
                </div>
                <div className="edit-team-performance-comparison-button bold">
                    <div className="comparison-button-text">RUN COMPARISON SUMMARY</div>
                    <img src={comparison} alt="Run comparison summary"/>
                </div>
            </div>
            <div className="edit-team-performance-key-container">
                <div className="edit-team-performance-key">
                    <div className="edit-team-performance-key-circle pink"/>
                    <div className="edit-team-performance-key-text regular">Lowest Score</div>
                </div>
                <div className="edit-team-performance-key">
                    <div className="edit-team-performance-key-circle purple"/>
                    <div className="edit-team-performance-key-text regular">Team Mean</div>
                </div>
                <div className="edit-team-performance-key">
                    <div className="edit-team-performance-key-circle green"/>
                    <div className="edit-team-performance-key-text regular">Highest Score</div>
                </div>
            </div>
            <div className="edit-team-performance-graph">
                <svg height="400" width="1050" xmlns="http://www.w3.org/2000/svg">
                    <defs>
                        <path id="y-label" d="M 10 350 L 10 0"/>
                        <path id="x-label" d="M 0 390 L 1050 390"/>
                    </defs>
                    <line x1="50" y1="10" x2="1050" y2="10" style={{stroke: "#CBCBCB", strokeWidth: ".5"}}/>
                    <line x1="50" y1="70" x2="1050" y2="70" style={{stroke: "#CBCBCB", strokeWidth: ".5"}}/>
                    <line x1="50" y1="130" x2="1050" y2="130" style={{stroke: "#CBCBCB", strokeWidth: ".5"}}/>
                    <line x1="50" y1="190" x2="1050" y2="190" style={{stroke: "#CBCBCB", strokeWidth: ".5"}}/>
                    <line x1="50" y1="250" x2="1050" y2="250" style={{stroke: "#CBCBCB", strokeWidth: ".5"}}/>
                    <line x1="50" y1="310" x2="1050" y2="310" style={{stroke: "#CBCBCB", strokeWidth: ".5"}}/>

                    <line x1="50" y1="10" x2="50" y2="310"
                          style={{stroke: "#CBCBCB", strokeWidth: ".5", strokeDasharray: "2 4"}}/>
                    <line x1="161" y1="10" x2="161" y2="310"
                          style={{stroke: "#CBCBCB", strokeWidth: ".5", strokeDasharray: "2 4"}}/>
                    <line x1="272" y1="10" x2="272" y2="310"
                          style={{stroke: "#CBCBCB", strokeWidth: ".5", strokeDasharray: "2 4"}}/>
                    <line x1="383" y1="10" x2="383" y2="310"
                          style={{stroke: "#CBCBCB", strokeWidth: ".5", strokeDasharray: "2 4"}}/>
                    <line x1="494" y1="10" x2="494" y2="310"
                          style={{stroke: "#CBCBCB", strokeWidth: ".5", strokeDasharray: "2 4"}}/>
                    <line x1="605" y1="10" x2="605" y2="310"
                          style={{stroke: "#CBCBCB", strokeWidth: ".5", strokeDasharray: "2 4"}}/>
                    <line x1="716" y1="10" x2="716" y2="310"
                          style={{stroke: "#CBCBCB", strokeWidth: ".5", strokeDasharray: "2 4"}}/>
                    <line x1="827" y1="10" x2="827" y2="310"
                          style={{stroke: "#CBCBCB", strokeWidth: ".5", strokeDasharray: "2 4"}}/>
                    <line x1="938" y1="10" x2="938" y2="310"
                          style={{stroke: "#CBCBCB", strokeWidth: ".5", strokeDasharray: "2 4"}}/>
                    <line x1="1049" y1="10" x2="1049" y2="310"
                          style={{stroke: "#CBCBCB", strokeWidth: ".5", strokeDasharray: "2 4"}}/>
                    <text style={{fill: "#000000", fontSize: 14, userSelect: "none", msUserSelect: "none"}} className="regular">
                        <textPath href="#y-label" lengthAdjust="spacing" textLength="37" startOffset="180">
                            Score
                        </textPath>
                    </text>
                    <text style={{fill: "#000000", fontSize: 14, userSelect: "none", msUserSelect: "none"}} className="regular">
                        <textPath href="#x-label" lengthAdjust="spacing" textLength="160" startOffset="470">
                            Survey Engagement Point
                        </textPath>
                    </text>
                    <text style={{fill: "#979BBA", fontSize: 12}} x="30" y="10" className="medium">50</text>
                    <text style={{fill: "#979BBA", fontSize: 12}} x="30" y="70" className="medium">40</text>
                    <text style={{fill: "#979BBA", fontSize: 12}} x="30" y="130" className="medium">30</text>
                    <text style={{fill: "#979BBA", fontSize: 12}} x="30" y="190" className="medium">20</text>
                    <text style={{fill: "#979BBA", fontSize: 12}} x="30" y="250" className="medium">10</text>
                    <text style={{fill: "#979BBA", fontSize: 12}} x="30" y="310" className="medium">0</text>
                    {usableSurveys.map((survey, idx) => {
                        return (
                            <>
                                <text style={{fill: "#065683", fontSize: 14, textAnchor: "middle"}} className="bold"
                                      x={survey.x} y={330}>
                                    {survey.point}
                                </text>

                                <rect x={survey.x - 60} y={340} width={120} height={30} rx={6}
                                      style={{fill: "#EBF7FF"}}/>

                                <text style={{fill: "#065683", fontSize: 15, textAnchor: "middle"}} className="regular"
                                      x={survey.x} y={360}>
                                    {formatDate(survey.date)}
                                </text>

                                <circle cx={survey.x} cy={calculatePointHeight(survey.lowest)} r={11}
                                        style={{fill: "#FFE2F4"}}/>
                                <circle cx={survey.x} cy={calculatePointHeight(survey.lowest)} r={8}
                                        style={{fill: "#F478C4"}}/>

                                {!survey.last ?
                                    <>
                                        <line x1={survey.x} x2={getLineEndpoint(idx, "lowest")[0]}
                                              y1={calculatePointHeight(survey.lowest)}
                                              y2={getLineEndpoint(idx, "lowest")[1]}
                                              style={{stroke: "#F478C4", strokeWidth: "3"}}/>
                                    </>
                                    : null
                                }

                                <circle cx={survey.x} cy={calculatePointHeight(survey.avg)} r={11}
                                        style={{fill: "#DCC6F7"}}/>
                                <circle cx={survey.x} cy={calculatePointHeight(survey.avg)} r={8}
                                        style={{fill: "#A128ED"}}/>

                                {!survey.last ?
                                    <>
                                        <line x1={survey.x} x2={getLineEndpoint(idx, "avg")[0]}
                                              y1={calculatePointHeight(survey.avg)}
                                              y2={getLineEndpoint(idx, "avg")[1]}
                                              style={{stroke: "#A128ED", strokeWidth: "3"}}/>
                                    </>
                                    : null
                                }

                                <circle cx={survey.x} cy={calculatePointHeight(survey.highest)} r={11}
                                        style={{fill: "#CBEFE9"}}/>
                                <circle cx={survey.x} cy={calculatePointHeight(survey.highest)} r={8}
                                        style={{fill: "#4CD1BD"}}/>

                                {!survey.last ?
                                    <>
                                        <line x1={survey.x} x2={getLineEndpoint(idx, "highest")[0]}
                                              y1={calculatePointHeight(survey.highest)}
                                              y2={getLineEndpoint(idx, "highest")[1]}
                                              style={{stroke: "#4CD1BD", strokeWidth: "3"}}/>
                                    </>
                                    : null
                                }
                            </>
                        )
                    })}
                </svg>
            </div>
        </div>
    )
}

export default EditTeamPerformanceContainer