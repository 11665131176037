import {useEffect, useState} from "react";
import ImageUtils from "../../utils/ImageUtils";
import companies_api from "../../api/protected/companies";
import GenericDialog from "../GenericDialog";
import sect_api from "../../api/sectors";
import {useNavigate} from "react-router-dom";


const CompanyForm = (props) => {

    const navigate = useNavigate();

    const [companyName, setCompanyName] = useState("");
    const [companyDescription, setCompanyDescription] = useState("");
    const [companySector, setCompanySector] = useState(0)
    const [companyLogo, setCompanyLogo] = useState()
    const [companyLogoBase64, setCompanyLogoBase64] = useState("")
    const [companyLogoFileType, setCompanyLogoFileType] = useState("")
    const [companyID, setCompanyID] = useState()
    const [editor, setEditor] = useState(false)

    const [sectors, setSectors] = useState([])

    const [error, setError] = useState('');
    const buttons = [{"text":"OK", "id": "1"}];

    const [companyMax, setCompanyMax] = useState('');
    const companyMaxButtons = [{"text":"Cancel", "id": "1"}, {"text":"Upgrade", "id": "2"}];

    const [buttonText, setButtonText] = useState("")
    const updateData = props.updateData

    const [showSectorWarning, setShowSectorWarning] = useState(false)
    const [showUpdateState, setShowUpdateState] = useState(false)
    const [updateStateText, setUpdateStateText] = useState("")

    useEffect(() => {
        setShowSectorWarning(!companySector || companySector === 0)
    }, [companySector])

    useEffect(() => {
        if (!props.buttonText) return;
        setButtonText(props.buttonText)
    }, [props.buttonText])

    useEffect(() => {
        if (!props.companyName) return;
        setCompanyName(props.companyName)
    }, [props.companyName]);

    useEffect(() => {
        if (!props.companyDescription) return;
        setCompanyDescription(props.companyDescription)
    }, [props.companyDescription]);

    useEffect(() => {
        if (!props.companyLogo) return;
        setCompanyLogo(props.companyLogo)
    }, [props.companyLogo]);

    useEffect(() => {
        if (!props.companyID) return;
        setCompanyID(props.companyID)
    }, [props.companyID]);

    useEffect(() => {
        if (!props.companySector) {
            setShowSectorWarning(true);
            return;
        }
        setCompanySector(props.companySector)
        setShowSectorWarning(false)
    }, [props.companySector])

    useEffect(() => {
        if(!props.editor) return;
        setEditor(props.editor)
    }, [props.editor])

    useEffect(() => {
        getSectors()
    }, []);


    const formSubmit = (ev) => {
        ev.preventDefault();
        if (!companyID) NewCompany()
        else EditCompany()
    }

    const EditCompany = () => {
        if (!companyID) return;
        let body = {
            "name": companyName,
            "description": companyDescription,
            "sector": companySector
        }
        let response = companies_api.updateCompany(companyID, body);
        response.then(() => {
            handleCompanyLogoUpdate(companyID)
        }).catch(() => {
            setUpdateStateText("Failed to update company details.")
            setShowUpdateState(true)
        }).finally(() => {
            setUpdateStateText("Company details updated successfully.")
            setShowUpdateState(true)
        });
    }

    const NewCompany = ()=> {
        let body = {
            "name": companyName,
            "description": companyDescription,
            "sector": companySector,
        }
        let response = companies_api.newCompany(body);
        response.then(response => {
            if (response.data.company_id === false) {
                setCompanyMax("To create a new company, upgrade your plan or delete a " +
                    "existing Company.")
            } else {
                if (companyLogoBase64 !== "") {
                    handleCompanyLogoUpdate(response.data.company_id)
                } else {
                    updateData()
                }
            }
        }).catch()
    }

    const getSectors = () => {
        let response = sect_api.getSectors();
        response.then(response => {
            let _sectors = response.data.sectors
            let _initial = [{"id": 0, "name":""}]
            _sectors.forEach((element) => {
                let _sector = {"id":element.id, "name":element.name}
                _initial.push(_sector)
            });
            setSectors(_initial);
        }).catch(err => console.log(err))
    }

    const updateCompanyLogoFileType = (string) => {
        if (string === "" || string === undefined) return;
        setCompanyLogoFileType(string)
    }

    const handleCompanyLogoUpdate = (company_id) => {
        if (!companyLogoBase64 || !companyLogoFileType || !company_id) {
            updateData()
            return;
        }
        let body = {
            "file_format": companyLogoFileType,
            "image_string": companyLogoBase64.split(',')[1],
            "company_id": company_id
        }
        let response = companies_api.updateCompanyLogo(body);
        response.then(() => updateData()).catch(() => {
            setError("Failed to update the company. Please ensure any image uploaded is a recognized format " +
                "and is not too large");
        })
    }

    const handleCompanyImageClick = () => {
        let element = document.getElementById("company-logo-uploader")
        if (!element) return;
        element.click()
    }

    const handleErrorDialogResult = (res) => {
        if(!res) return;
        setError("")
        if (companyID === undefined) {
            updateData()
            navigate("/companies")
        }
    }

    const handleCompanyMaxDialogResult = (res) => {
        if(!res) return;
        setCompanyMax("")
        if (res === "1") {
            updateData()
            navigate("/companies")
        } else {
            navigate("/pricing-plans")
        }
    }

    const handleSuccessButton = (res) => {
        if(!res) return;
        setShowUpdateState(false)
        setUpdateStateText("")
    }

    return (
        <div className="company-form-container">
            <div className="company-form-title">Company Details</div>
            <form name="company-form" method="POST">
                <div className="field-input">
                    <div className="label">Company Name</div>
                    <div className="field-input-entry">
                        <input className="company-name-input" name="company.name" type="text" value={companyName}
                               onChange={ev => setCompanyName(ev.target.value)}
                               required disabled={companyID && !editor}
                        />
                    </div>
                </div>
                <div className="field-input">
                    <div className="field-input-entry">
                        <div className="label">Company Description</div>
                        <input name="company.description" type="text" maxLength="50" value={companyDescription}
                               onChange={(ev) => setCompanyDescription(ev.target.value)}
                               disabled={companyID && !editor}
                        />
                    </div>
                </div>
                <div className="field-input">
                    <div className="field-input-entry">
                        <div className="label">Company Business/Industry Sector</div>
                        <select name="company.sector" value={companySector} required
                                onChange={ev => setCompanySector(ev.target.value)}
                                disabled={companyID && !editor}>
                            {sectors && sectors.map((sector) => {
                                return <option value={sector.id}>{sector.name}</option>
                            })}
                        </select>
                        {showSectorWarning && !props.companyDemo ?
                            <div className="messages company-form">Please set this companies sector.</div>
                            : null
                        }
                    </div>
                </div>
                <div className="field-input">
                    <div className="label">Company Logo</div>
                    <div className="field-input-entry">
                        <div className="settings-image-container link" onClick={() => handleCompanyImageClick()}>
                            {companyLogoBase64 && companyLogoBase64 ?
                                <img src={companyLogoBase64} className="settings-image" alt="Profile Picture"/>
                                : companyLogo && companyLogo ?
                                    <img src={companyLogo} className="settings-image" alt="Profile Picture"/>
                                    : null
                            }
                        </div>
                        <input accept="image/*" type='file' id="company-logo-uploader"
                               onChange={async (e) => {
                                   let base64_image = await ImageUtils.convertBase64(e.target.files[0]);
                                   setCompanyLogoBase64(base64_image);
                                   updateCompanyLogoFileType(e.target.files[0].name.split(".")[1]);
                               }}
                               className="company-logo-uploader-hidden" disabled={companyID && !editor}
                        />
                    </div>
                </div>
                {!companyID || (companyID && editor) ?
                    <div className="field-input">
                        <button className="update-company-button link" name="method" type="submit" value="password"
                                disabled={!companyName || !companySector}
                                onClick={formSubmit}>
                            {buttonText && buttonText}
                        </button>
                    </div>
                    : null
                }
            </form>
            {error && error ?
                <GenericDialog title="Update Error" text={error} buttons={buttons}
                               result={handleErrorDialogResult.bind(this)}/>
                : null
            }
            {showUpdateState ?
                <GenericDialog title="Company Update" text={updateStateText} buttons={buttons}
                               result={handleSuccessButton.bind(this)}/>
                : null
            }
            {companyMax && companyMax ?
                <GenericDialog title="Maximum Companies reach" text={companyMax} buttons={companyMaxButtons}
                               result={handleCompanyMaxDialogResult.bind(this)}/>
                : null
            }
        </div>
    )
}


export default CompanyForm