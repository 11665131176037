import {useEffect, useState} from "react";
import TeamsCard from "./TeamsCard";
import dashboard_api from "../../api/protected/dashboard"


const TeamsCardContainer = () => {

    const [teams, setTeams] = useState([])

    useEffect(() => {
        getTeamCards()
    }, []);

    const getTeamCards = () => {
        let response = dashboard_api.getTeamCards();
        response.then(response => {
            setTeams(response.data)
        }).catch(err => console.log(err))
    }

    return teams.map(team => {return <TeamsCard team_id={team}/>})
}


export default TeamsCardContainer