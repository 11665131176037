import collapse from "../assets/collapse.svg"
import expand from "../assets/expand-teams.svg"
import {useEffect, useState} from "react";
import companies_api from "../api/protected/companies";


const EditTeamDetailsContainer = (props) => {

    const editor = props.editor
    const submit = props.submit
    const [teamName, setTeamName] = useState("")
    const [teamNameEdit, setTeamNameEdit] = useState("")
    const [description, setDescription] = useState("")
    const [descriptionEdit, setDescriptionEdit] = useState("")
    const [companyName, setCompanyName] = useState("")
    const [collapsed, setCollapsed] = useState(false)

    useEffect(() => {
        setTeamName(props.name)
        setTeamNameEdit(props.name)
        setDescription(props.description)
        setDescriptionEdit(props.description)
        getCompanies()
    }, [props]);

    const getCompanies = () => {
        let response = companies_api.getCompanies();
        response.then(response => {
            for (const company of response.data.companies) {
                if (company.id === props.companyId) {
                    setCompanyName(company.name)
                }
            }
        }).catch(err => console.log(err))
    }

    const updateTeamName = (ev) => {
        if(!ev) return;
        setTeamNameEdit(ev.target.value)
    }

    const updateDescription = (ev) => {
        if(!ev) return;
        setDescriptionEdit(ev.target.value)
    }

    const handleCancelEdit = (ev) => {
        if(!ev) return;
        setTeamNameEdit(teamName)
        setDescriptionEdit(description)
    }

    const handleUpdate = (ev) => {
        if(!ev || props.isDemo) return;
        let body = {
            "name": teamNameEdit,
            "description": descriptionEdit,
        }
        submit(body)
    }

    const handleCollapse = (ev) => {
        if(!ev) return;
        setCollapsed(!collapsed)
    }


    return (
        <div className={`edit-team-details-container ${collapsed ? "collapsed" : ""}`}>
            <div className={`edit-team-details-top-row ${collapsed ? "collapsed" : ""}`}>
                <div className="edit-team-details-title bold">Team Details</div>
                <div className={`edit-team-details-collapse ${collapsed ? "collapsed" : ""}`} onClick={handleCollapse}>
                    <img src={collapsed ? expand : collapse} alt={collapsed ? "Show team details" : "Hide team details"}/>
                </div>
            </div>
            {!collapsed ?
                <>
                    <div className="edit-team-details-middle-row">
                        <div className="edit-team-details-middle-row-section">
                            <div className="edit-team-details-subheading medium">Company</div>
                            <div className="edit-team-details-input regular">
                                <input type="text" value={companyName} disabled={true}/>
                            </div>
                            <div className="edit-team-details-subheading medium">Team Name</div>
                            <div className="edit-team-details-input regular">
                                <input type="text" value={teamNameEdit} onChange={updateTeamName} disabled={!editor || props.isDemo}/>
                            </div>
                        </div>
                        <div className="edit-team-details-middle-row-section">
                            <div className="edit-team-details-subheading medium">Team Description</div>
                            <div className="edit-team-details-textarea regular">
                                <textarea value={descriptionEdit} onChange={updateDescription} disabled={!editor || props.isDemo}/>
                            </div>
                        </div>
                    </div>
                    <div className="edit-team-details-bottom-row" style={{display: props.isDemo || !editor ? "none" : "flex"}}>
                        <div className="edit-team-details-button bold" onClick={handleCancelEdit}>CANCEL</div>
                        <div className="edit-team-details-button bold fill" onClick={handleUpdate}>UPDATE</div>
                    </div>
                </> : null
            }
        </div>
    )
}


export default EditTeamDetailsContainer