import {useEffect, useState} from "react";
import comparison_api from "../api/protected/comparison";
import back_arrow from "../assets/backarrow.svg";
import down_arrow from "../assets/red_arrow_down.png"
import up_arrow from "../assets/green_arrow_up.png"
import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import ComparisonSurveyCard from "../components/ComparisonSurveyCard";
import SummaryChart from "../components/SummaryChart";
import ComparisonSummaryChart from "../components/ComparisonSummaryChart";

const DiagnosticEngagement = () => {
    const navigate = useNavigate();
    const params = useParams()
    const [searchParams, setSearchParams] = useSearchParams()
    const demoSurveys = searchParams.get('demo')

    const [firstSurveyId, setFirstSurveyId] = useState()
    const [secondSurveyId, setSecondSurveyId] = useState()

    const [data, setData] = useState()
    const [selectedPillar, setSelectedPillar] = useState({id: 0, pillar: "Purpose & Motivation"})
    const [tabularColour, setTabularColour] = useState("blue")
    const [firstThemes, setFirstThemes] = useState()
    const [secondThemes, setSecondThemes] = useState()

    const [showTeamMembers, setShowTeamMembers] = useState(true)
    const [showTeamLeaders, setShowTeamLeaders] = useState(true)
    const [showStakeholders, setShowStakeholders] = useState(true)

    const handleBackClick = (ev) => {
        if(!ev) return;
        navigate(`/surveys`)
    }

    const normal_round = (value) => {
        if (!value) return;
        if (value - Math.floor(value) < 0.5) return Math.floor(value);
        return Math.ceil(value)
    }

    useEffect(() => {
        if (!firstSurveyId || !secondSurveyId) return;
        if (demoSurveys === "true") {
            let response = comparison_api.getDemoComparisons(firstSurveyId, secondSurveyId)
            response.then(response => {
                setData(response.data)
            }).catch(() => {
                console.log("error")
            })
        } else {
            let response = comparison_api.getComparisons(firstSurveyId, secondSurveyId)
            response.then(response => {
                setData(response.data)
            }).catch(() => {
                console.log("error")
            })
        }
    }, [firstSurveyId, secondSurveyId]);

    useEffect(() => {
        if (!params.firstSurveyId) return;
        setFirstSurveyId(params.firstSurveyId)
    }, [params.firstSurveyId]);

    useEffect(() => {
        if (!params.secondSurveyId) return;
        setSecondSurveyId(params.secondSurveyId)
    }, [params.secondSurveyId]);

    const getComparisonArrow = (idx, role) => {
        let _first_value = 0
        let _second_value = 0
        if (role) {
            let _role = "tm"
            switch (role) {
                case 2:
                    _role = "tl"
                    break;
                case 3:
                    _role = "sh"
                    break;
            }
            _first_value = getRoleAverageScore(firstThemes[idx], _role)
            _second_value = getRoleAverageScore(secondThemes[idx], _role)
        } else {
            _first_value = getTeamAverageScore(firstThemes[idx])
            _second_value = getTeamAverageScore(secondThemes[idx])
        }

        if (_first_value < _second_value) {
            return(<img src={up_arrow} alt="positive"/>)
        } else {
            return(<img src={down_arrow} alt="negative"/>)
        }
    }

    const getRoleAverageScore = (theme, role) => {
        if (!theme || !role) return 0;
        let _total = 0
        let _length = 0
        switch (role) {
            case "tm":
                for (let i = 0;i<theme.team_member_scores.length;i++) {
                    _total += theme.team_member_scores[i].score
                    _length += 1
                }
                break;
            case "tl":
                for (let i = 0;i<theme.team_leader_scores.length;i++) {
                    _total += theme.team_leader_scores[i].score
                    _length += 1
                }
                break;
            case "sh":
                for (let i = 0;i<theme.stakeholder_scores.length;i++) {
                    _total += theme.stakeholder_scores[i].score
                    _length += 1
                }
                break;
        }
        if (!_length) return 0;
        return normal_round(_total/_length);
    }

    const getTeamAverageScore = (theme) => {
        let _total = 0
        let _length = 0
        if (showTeamMembers) {
            for (let i = 0;i<theme.team_member_scores.length;i++) {
                _total += theme.team_member_scores[i].score
                _length += 1
            }
        }
        if (showTeamLeaders) {
            for (let i = 0;i<theme.team_leader_scores.length;i++) {
                _total += theme.team_leader_scores[i].score
                _length += 1
            }
        }
        if (showStakeholders) {
            for (let i = 0;i<theme.stakeholder_scores.length;i++) {
                _total += theme.stakeholder_scores[i].score
                _length += 1
            }
        }
        if (!_length) return 0;
        return normal_round(_total/_length)
    }

    const getFunctionValue = (idx) => {
        let _first = getTeamAverageScore(firstThemes[idx])
        let _second = getTeamAverageScore(secondThemes[idx])

        let _avg = (_first + _second) / 2
        if (_avg >= 30) {
            return "High"
        } else if (30 > _avg >= 15) {
            return "Medium"
        } else {
            return "Low"
        }
    }

    const getSurveyPoint = (point) => {
        let _value = "Initial"
        if (!point) return _value;
        switch (point) {
            case 2:
                _value = "Midpoint"
                break;
            case 3:
                _value = "Endpoint"
                break;
            case 4:
                _value = "Post Engagement"
                break;
        }
        return _value;
    }

    useEffect(() => {
        if (!selectedPillar || !data) return;
        let _first_survey = data.surveys[0].pillars[selectedPillar.id].themes
        let _second_survey = data.surveys[1].pillars[selectedPillar.id].themes
        setFirstThemes(_first_survey)
        setSecondThemes(_second_survey)
        switch (selectedPillar.id) {
            case 0:
                setTabularColour("blue")
                break;
            case 1:
                setTabularColour("red")
                break;
            case 2:
                setTabularColour("green")
                break;
            case 3:
                setTabularColour("purple")
                break;
            case 4:
                setTabularColour("pink")
                break;
            case 5:
                setTabularColour("orange")
                break;
        }
    }, [selectedPillar, data]);

    return(
        <>
            <div className="comparison-page-background-container">
                <div className="comparison-page-container">
                    <div className="link" onClick={handleBackClick}>
                        <img src={back_arrow} alt={"Back Arrow"}/>
                    </div>
                    <div className="comparison-title">Diagnostic Engagement Review</div>
                    <div className="comparison-header-container">
                        {data && data ?
                            <>
                                <div className="comparison-header-title-container start">
                                    <div className="comparison-header-title">
                                        Company:
                                    </div>
                                    <div className="comparison-header-value">
                                        {data.company_name}
                                    </div>
                                </div>
                                <div className="comparison-header-title-container end">
                                    <div className="comparison-header-title">
                                        Team:
                                    </div>
                                    <div className="comparison-header-value">
                                        {data.team_name}
                                    </div>
                                </div>
                            </>
                        :null}
                    </div>
                    {data && data ?
                        <div className="comparison-cards-container">
                            {data.surveys && data.surveys.map((survey, idx) => {
                                return(<ComparisonSurveyCard survey={survey} idx={idx} />)
                            })}
                        </div>
                        :null}
                    <div className="comparison-role-filter-container">
                        <div className="comparison-role-container">
                            <div className="summary-tabular-controls-text-container">
                                <div className="summary-tabular-colour-circle green"/>
                                <div className="comparison-role-controls-text">Team Members</div>
                            </div>
                            <div className="summary-tabular-controls-switch">
                                <div className={`chart-controls-switch ${showTeamMembers ? "active" : "inactive"}`}
                                     onClick={() => setShowTeamMembers(!showTeamMembers)}>
                                    <div className="chart-controls-switch-centre"/>
                                </div>
                            </div>
                        </div>
                        <div className="comparison-role-container">
                            <div className="summary-tabular-controls-text-container">
                                <div className="summary-tabular-colour-circle pink"/>
                                <div className="comparison-role-controls-text">Team Leader(s)</div>
                            </div>
                            <div className="summary-tabular-controls-switch">
                                <div className={`chart-controls-switch ${showTeamLeaders ? "active" : "inactive"}`}
                                     onClick={() => setShowTeamLeaders(!showTeamLeaders)}>
                                    <div className="chart-controls-switch-centre"/>
                                </div>
                            </div>
                        </div>
                        <div className="comparison-role-container">
                            <div className="summary-tabular-controls-text-container">
                                <div className="summary-tabular-colour-circle blue"/>
                                <div className="comparison-role-controls-text">Stakeholder(s)</div>
                            </div>
                            <div className="summary-tabular-controls-switch">
                                <div className={`chart-controls-switch ${showStakeholders ? "active" : "inactive"}`}
                                     onClick={() => setShowStakeholders(!showStakeholders)}>
                                    <div className="chart-controls-switch-centre"/>
                                </div>
                            </div>
                        </div>
                    </div>
                    {data && data ?
                        <div className="comparison-charts-container">
                            <div className="comparison-chart-card-container">
                                <div className="comparison-interactive-chart start">
                                    <ComparisonSummaryChart surveyData={data.surveys[0]}
                                                            tmEnabled={showTeamMembers}
                                                            tlEnabled={showTeamLeaders}
                                                            stEnabled={showStakeholders}
                                                            showPillars={true}
                                                            chartId={1}
                                    />
                                </div>
                            </div>
                            <div className="comparison-chart-card-container">
                                <div className="comparison-interactive-chart end">
                                    <ComparisonSummaryChart surveyData={data.surveys[1]}
                                                            tmEnabled={showTeamMembers}
                                                            tlEnabled={showTeamLeaders}
                                                            stEnabled={showStakeholders}
                                                            showPillars={true}
                                                            chartId={2}
                                    />
                                </div>
                            </div>
                        </div>
                        :null}
                    <div className="comparison-pillar-filter">
                        <div className="comparison-pillar-text">Select Pillar</div>
                        <div className={selectedPillar.id === 0 ?
                            "comparison-pillar-container blue on" : "comparison-pillar-container blue off"}
                             onClick={() => setSelectedPillar({id: 0, pillar: "Purpose & Motivation"})}/>
                        <div className={selectedPillar.id === 1 ?
                            "comparison-pillar-container red on" : "comparison-pillar-container red off"}
                             onClick={() => setSelectedPillar({id: 1, pillar: "External Systems & Processes"})}/>
                        <div className={selectedPillar.id === 2 ?
                            "comparison-pillar-container green on" : "comparison-pillar-container green off"}
                             onClick={() => setSelectedPillar({id: 2, pillar: "Relationships"})}/>
                        <div className={selectedPillar.id === 3 ?
                            "comparison-pillar-container purple on" : "comparison-pillar-container purple off"}
                             onClick={() => setSelectedPillar({id: 3, pillar: "Internal Systems & Processes"})}/>
                        <div className={selectedPillar.id === 4 ?
                            "comparison-pillar-container pink on" : "comparison-pillar-container pink off"}
                             onClick={() => setSelectedPillar({id: 4, pillar: "Learning"})}/>
                        <div className={selectedPillar.id === 5 ?
                            "comparison-pillar-container orange on" : "comparison-pillar-container orange off"}
                             onClick={() => setSelectedPillar({id: 5, pillar: "Leadership"})}/>
                    </div>
                    <div className="comparison-tabular-card-container">
                        <div className="comparison-tabular-card-header">Tabular</div>
                        {firstThemes ?
                            <div className="comparison-tabular-header-row">
                                <div className="comparison-tabular-header empty"/>
                                <div className="comparison-tabular-header">{firstThemes[0].name}</div>
                                <div className="comparison-tabular-header">{firstThemes[1].name}</div>
                                <div className="comparison-tabular-header">{firstThemes[2].name}</div>
                                <div className="comparison-tabular-header">{firstThemes[3].name}</div>
                                <div className="comparison-tabular-header">{firstThemes[4].name}</div>
                            </div>
                            :null}
                        <div className="comparison-tabular-colour-row">
                            <div className="comparison-tabular-colour-container empty">
                                <div className="comparison-tabular-colour empty"/>
                            </div>
                            {firstThemes && firstThemes.map((theme, idx) => {
                                return(
                                    <div className="comparison-tabular-colour-container">
                                        <div className={`comparison-tabular-colour ${tabularColour}`} key={idx}/>
                                    </div>
                                )
                            })}
                        </div>
                        <div className="comparison-tabular-point-row">
                            <div className="comparison-tabular-point-column start">Survey Point</div>
                            {firstThemes && firstThemes.map((theme, idx) => {
                                return(
                                    <>
                                        <div className="comparison-tabular-point-column">
                                            <div className="comparison-tabular-point">{getSurveyPoint(data.surveys[0].survey_point)}</div>
                                            <div className="comparison-tabular-point">{getSurveyPoint(data.surveys[1].survey_point)}</div>
                                        </div>
                                    </>
                                )
                            })}
                        </div>
                        {showTeamMembers && firstThemes && secondThemes ?
                            <div className="comparison-tabular-role-row light">
                                <div className="comparison-tabular-role-column">
                                    <div className="comparison-tabular-role-icon tm"/>
                                    Team Member Mean
                                </div>
                                <div className="comparison-tabular-theme-column">
                                    <div className="comparison-tabular-theme-value mid">{getRoleAverageScore(firstThemes[0], "tm")}</div>
                                    <div className="comparison-tabular-theme-value">
                                        {getRoleAverageScore(secondThemes[0], "tm")}
                                        {getComparisonArrow(0, 1)}
                                    </div>
                                </div>
                                <div className="comparison-tabular-theme-column">
                                    <div className="comparison-tabular-theme-value mid">{getRoleAverageScore(firstThemes[1], "tm")}</div>
                                    <div className="comparison-tabular-theme-value">
                                        {getRoleAverageScore(secondThemes[1], "tm")}
                                        {getComparisonArrow(1, 1)}
                                    </div>
                                </div>
                                <div className="comparison-tabular-theme-column">
                                    <div className="comparison-tabular-theme-value mid">{getRoleAverageScore(firstThemes[2], "tm")}</div>
                                    <div className="comparison-tabular-theme-value">
                                        {getRoleAverageScore(secondThemes[2], "tm")}
                                        {getComparisonArrow(2, 1)}
                                    </div>
                                </div>
                                <div className="comparison-tabular-theme-column">
                                    <div className="comparison-tabular-theme-value mid">{getRoleAverageScore(firstThemes[3], "tm")}</div>
                                    <div className="comparison-tabular-theme-value">
                                        {getRoleAverageScore(secondThemes[3], "tm")}
                                        {getComparisonArrow(3, 1)}
                                    </div>
                                </div>
                                <div className="comparison-tabular-theme-column">
                                    <div className="comparison-tabular-theme-value mid">{getRoleAverageScore(firstThemes[4], "tm")}</div>
                                    <div className="comparison-tabular-theme-value">
                                        {getRoleAverageScore(secondThemes[4], "tm")}
                                        {getComparisonArrow(4, 1)}
                                    </div>
                                </div>
                            </div>
                            :null}
                        {showTeamLeaders && firstThemes && secondThemes ?
                            <div className="comparison-tabular-role-row mid">
                                <div className="comparison-tabular-role-column">
                                    <div className="comparison-tabular-role-icon tl"/>
                                    Team Leader Mean
                                </div>
                                <div className="comparison-tabular-theme-column">
                                    <div className="comparison-tabular-theme-value dark">{getRoleAverageScore(firstThemes[0], "tl")}</div>
                                    <div className="comparison-tabular-theme-value">
                                        {getRoleAverageScore(secondThemes[0], "tl")}
                                        {getComparisonArrow(0, 2)}
                                    </div>
                                </div>
                                <div className="comparison-tabular-theme-column">
                                    <div className="comparison-tabular-theme-value dark">{getRoleAverageScore(firstThemes[1], "tl")}</div>
                                    <div className="comparison-tabular-theme-value">
                                        {getRoleAverageScore(secondThemes[1], "tl")}
                                        {getComparisonArrow(1, 2)}
                                    </div>
                                </div>
                                <div className="comparison-tabular-theme-column">
                                    <div className="comparison-tabular-theme-value dark">{getRoleAverageScore(firstThemes[2], "tl")}</div>
                                    <div className="comparison-tabular-theme-value">
                                        {getRoleAverageScore(secondThemes[2], "tl")}
                                        {getComparisonArrow(2, 2)}
                                    </div>
                                </div>
                                <div className="comparison-tabular-theme-column">
                                    <div className="comparison-tabular-theme-value dark">{getRoleAverageScore(firstThemes[3], "tl")}</div>
                                    <div className="comparison-tabular-theme-value">
                                        {getRoleAverageScore(secondThemes[3], "tl")}
                                        {getComparisonArrow(3, 2)}
                                    </div>
                                </div>
                                <div className="comparison-tabular-theme-column">
                                    <div className="comparison-tabular-theme-value dark">{getRoleAverageScore(firstThemes[4], "tl")}</div>
                                    <div className="comparison-tabular-theme-value">
                                        {getRoleAverageScore(secondThemes[4], "tl")}
                                        {getComparisonArrow(4, 2)}
                                    </div>
                                </div>
                            </div>
                            :null}
                        {showStakeholders && firstThemes && secondThemes ?
                            <div className="comparison-tabular-role-row light">
                                <div className="comparison-tabular-role-column">
                                    <div className="comparison-tabular-role-icon sh"/>
                                    Stakeholder Mean
                                </div>
                                <div className="comparison-tabular-theme-column">
                                    <div className="comparison-tabular-theme-value mid">{getRoleAverageScore(firstThemes[0], "sh")}</div>
                                    <div className="comparison-tabular-theme-value">
                                        {getRoleAverageScore(secondThemes[0], "sh")}
                                        {getComparisonArrow(0, 3)}
                                    </div>
                                </div>
                                <div className="comparison-tabular-theme-column">
                                    <div className="comparison-tabular-theme-value mid">{getRoleAverageScore(firstThemes[1], "sh")}</div>
                                    <div className="comparison-tabular-theme-value">
                                        {getRoleAverageScore(secondThemes[1], "sh")}
                                        {getComparisonArrow(1, 3)}
                                    </div>
                                </div>
                                <div className="comparison-tabular-theme-column">
                                    <div className="comparison-tabular-theme-value mid">{getRoleAverageScore(firstThemes[2], "sh")}</div>
                                    <div className="comparison-tabular-theme-value">
                                        {getRoleAverageScore(secondThemes[2], "sh")}
                                        {getComparisonArrow(2, 3)}
                                    </div>
                                </div>
                                <div className="comparison-tabular-theme-column">
                                    <div className="comparison-tabular-theme-value mid">{getRoleAverageScore(firstThemes[3], "sh")}</div>
                                    <div className="comparison-tabular-theme-value">
                                        {getRoleAverageScore(secondThemes[3], "sh")}
                                        {getComparisonArrow(3, 3)}
                                    </div>
                                </div>
                                <div className="comparison-tabular-theme-column">
                                    <div className="comparison-tabular-theme-value mid">{getRoleAverageScore(firstThemes[4], "sh")}</div>
                                    <div className="comparison-tabular-theme-value">
                                        {getRoleAverageScore(secondThemes[4], "sh")}
                                        {getComparisonArrow(4, 3)}
                                    </div>
                                </div>
                            </div>
                            :null}
                        <div className="comparison-tabular-spacer-row">
                            <div className="comparison-tabular-role-column empty"/>
                            <div className="comparison-tabular-theme-column empty"/>
                            <div className="comparison-tabular-theme-column empty"/>
                            <div className="comparison-tabular-theme-column empty"/>
                            <div className="comparison-tabular-theme-column empty"/>
                            <div className="comparison-tabular-theme-column empty"/>
                        </div>
                        {firstThemes && secondThemes ?
                            <>
                                <div className="comparison-tabular-role-row mid">
                                    <div className="comparison-tabular-role-column">
                                        <div className="comparison-tabular-role-icon empty"/>
                                        Team Mean
                                    </div>
                                    <div className="comparison-tabular-theme-column">
                                        <div className="comparison-tabular-theme-value dark">{getTeamAverageScore(firstThemes[0])}</div>
                                        <div className="comparison-tabular-theme-value">
                                            {getTeamAverageScore(secondThemes[0])}
                                            {getComparisonArrow(0, 0)}
                                        </div>
                                    </div>
                                    <div className="comparison-tabular-theme-column">
                                        <div className="comparison-tabular-theme-value dark">{getTeamAverageScore(firstThemes[1])}</div>
                                        <div className="comparison-tabular-theme-value">
                                            {getTeamAverageScore(secondThemes[1])}
                                            {getComparisonArrow(1, 0)}
                                        </div>
                                    </div>
                                    <div className="comparison-tabular-theme-column">
                                        <div className="comparison-tabular-theme-value dark">{getTeamAverageScore(firstThemes[2])}</div>
                                        <div className="comparison-tabular-theme-value">
                                            {getTeamAverageScore(secondThemes[2])}
                                            {getComparisonArrow(2, 0)}
                                        </div>
                                    </div>
                                    <div className="comparison-tabular-theme-column">
                                        <div className="comparison-tabular-theme-value dark">{getTeamAverageScore(firstThemes[3])}</div>
                                        <div className="comparison-tabular-theme-value">
                                            {getTeamAverageScore(secondThemes[3])}
                                            {getComparisonArrow(3, 0)}
                                        </div>
                                    </div>
                                    <div className="comparison-tabular-theme-column">
                                        <div className="comparison-tabular-theme-value dark">{getTeamAverageScore(firstThemes[4])}</div>
                                        <div className="comparison-tabular-theme-value">
                                            {getTeamAverageScore(secondThemes[4])}
                                            {getComparisonArrow(4, 0)}
                                        </div>
                                    </div>
                                </div>
                                <div className="comparison-tabular-role-row light">
                                    <div className="comparison-tabular-role-column">
                                        <div className="comparison-tabular-role-icon empty"/>
                                        Function
                                    </div>
                                    <div className="comparison-tabular-theme-column">
                                        <div className="comparison-tabular-theme-value">{getFunctionValue(0)}</div>
                                    </div>
                                    <div className="comparison-tabular-theme-column">
                                        <div className="comparison-tabular-theme-value">{getFunctionValue(1)}</div>
                                    </div>
                                    <div className="comparison-tabular-theme-column">
                                        <div className="comparison-tabular-theme-value">{getFunctionValue(2)}</div>
                                    </div>
                                    <div className="comparison-tabular-theme-column">
                                        <div className="comparison-tabular-theme-value">{getFunctionValue(3)}</div>
                                    </div>
                                    <div className="comparison-tabular-theme-column">
                                        <div className="comparison-tabular-theme-value">{getFunctionValue(4)}</div>
                                    </div>
                                </div>
                            </>
                            :null}
                    </div>
                </div>
            </div>
        </>
    )
}

export default DiagnosticEngagement