import Rating from "./Rating";
import {useEffect, useState} from "react";
import q_api from "../api/public/questionnaire"
import logo from "../assets/ccmi-graphic-logo-color.svg";
import RoiRatingRow from "./questionnare/RoiRatingRow";
import RoiTextRow from "./questionnare/RoiTextRow";


const ROISurvey = (props) => {

    const [response, setResponse] = useState([])
    const [showUnansweredWarning, setShowUnansweredWarning] = useState(false);
    const [surveyComplete, setSurveyComplete] = useState(false);
    const [enableSubmit, setEnableSubmit] = useState(false)
    const [coachSubscription, setCoachSubscription] = useState(null)

    useEffect(() => {
        if (!props.coachSubscription) return;
        setCoachSubscription(props.coachSubscription)
    },[props.coachSubscription])

    useEffect(() => {
        let r = [null, null, null, null, null, null, null, null, null, null, null, null]
        setResponse(r)
    }, []);

    useEffect(() => {
        let _enable = true
        let _length = 6
        if (coachSubscription) {
            _length = response.length
        }
        for (let i=0;i<_length;i++) {
            if (response[i] === null) {
                _enable = false
                break;
            }
        }
        setEnableSubmit(_enable)
    },[response])

    const handleScoreChange = (value, id) => {
        if (typeof value === "string") {
            if (value.length > 256) return;
        }
        setShowUnansweredWarning(false)
        let r = [...response]
        r[id] = value;
        setResponse(r)
    }

    const handleFormSubmit = (ev) => {
        if(!ev || !props.uuid || !props.accessKey || !enableSubmit) return;
        let resp = q_api.postROI(props.uuid, props.accessKey, response)
        resp.then(() => setSurveyComplete(true)).catch(() => {})
    }


    return (
        !surveyComplete ?
            <div className="questionnaire-complete-roi-container">
                <div className="questionnaire-complete-card-container">
                    <div className="questionnaire-complete-logo finished">
                        <img src={logo} alt="ccmi logo"/>
                    </div>
                    <div className="questionnaire-complete-card-text-container">
                        <div className="questionnaire-complete-card-heading">You're Finished</div>
                        <div className="questionnaire-complete-card-sub-heading">
                            You’ve successfully completed your diagnostic questionnaire. Your responses have been
                            submitted.
                        </div>
                        <div className="questionnaire-complete-card-text">
                            Please help us further by completing a few extra questions on the overall  experience -
                            it should only take a couple of minutes. Please rate the following statements from 1 star
                            (lowest) to 10 stars (highest) based on how much you agree with them.
                        </div>
                    </div>
                </div>
                <div className="roi-card-container">
                    <div className="roi-card-header-container">
                        Feedback on your team Coach(es)
                    </div>
                    <RoiRatingRow number={"1."}
                                  idx={0}
                                  question={<div>The Team Coach(es) positively influenced the communication and
                                      effectiveness <b className="bold">of the team as a whole</b>?</div>}
                                  handleScoreChange={handleScoreChange.bind(this)}
                                  end={false}/>
                    <RoiRatingRow number={"2."}
                                  idx={1}
                                  question={<div>The Team Coach(es) positively influenced the communication and
                                      effectiveness of <b className="bold">you personally</b> within this team?</div>}
                                  handleScoreChange={handleScoreChange.bind(this)}
                                  end={false}/>
                    <RoiRatingRow number={"3."}
                                  idx={2}
                                  question={<div>I would recommend this Team Coach(es) to another team?</div>}
                                  handleScoreChange={handleScoreChange.bind(this)}
                                  end={false}/>
                    <RoiTextRow number={"4."}
                                idx={3}
                                question={<div>What would have made the team coaching experience more valuable for you?</div>}
                                handleScoreChange={handleScoreChange.bind(this)}
                                data={response}
                                end={false}/>
                    <RoiTextRow number={"5."}
                                idx={4}
                                question={<div>What was the most confusing or challenging part of the team coaching experience?</div>}
                                handleScoreChange={handleScoreChange.bind(this)}
                                data={response}
                                end={false}/>
                    <RoiTextRow number={"6."}
                                idx={5}
                                question={<div>Is there anything else you think we should know?</div>}
                                handleScoreChange={handleScoreChange.bind(this)}
                                data={response}
                                end={true}/>
                </div>
                {props.coachSubscription !== 0 ?
                    <div className="roi-card-container">
                        <div className="roi-card-header-container">
                            Feedback on your team Coach(es)
                        </div>
                        <RoiRatingRow number={"1."}
                                      idx={6}
                                      question={<div>The surveys were easy to access.</div>}
                                      handleScoreChange={handleScoreChange.bind(this)} />
                        <RoiRatingRow number={"2."}
                                      idx={7}
                                      question={<div>The surveys were easy to complete.</div>}
                                      handleScoreChange={handleScoreChange.bind(this)}
                                      end={false}/>
                        <RoiRatingRow number={"3."}
                                      idx={8}
                                      question={<div>The survey summaries / reports were effective in uncovering our team
                                          challenges and opportunities.</div>}
                                      handleScoreChange={handleScoreChange.bind(this)}
                                      end={false}/>
                        <RoiRatingRow number={"4."}
                                      idx={9}
                                      question={<div>The survey summaries / reports were effective in improving our team
                                          communication and effectiveness.</div>}
                                      handleScoreChange={handleScoreChange.bind(this)}
                                      end={false}/>
                        <RoiRatingRow number={"5."}
                                      idx={10}
                                      question={<div>I would recommend PERILL Diagnostics for Teams to another team.</div>}
                                      handleScoreChange={handleScoreChange.bind(this)}
                                      end={false}/>
                        <RoiTextRow number={"6."}
                                    idx={11}
                                    question={<div>How could we improve the diagnostic survey or reports to make it easier
                                        to understand or use?</div>}
                                    handleScoreChange={handleScoreChange.bind(this)}
                                    data={response}
                                    end={true}/>
                    </div>
                    :null}
                <div className="roi-survey-warning-text">{showUnansweredWarning ? "Please answer all questions before submitting." : ""}</div>
                <div className={enableSubmit ? "submit-questionnaire-button roi link"
                    : "submit-questionnaire-button roi disabled"} onClick={handleFormSubmit}>Submit</div>
            </div>
            :
            <div className="questionnaire-complete-header">
                <div className="questionnaire-complete-logo">
                    <img src={logo} alt="ccmi logo"/>
                </div>
                <h2>You're Finished</h2>
                <h3>Thank you. You have now completed your diagnostics questionnaire.</h3>
            </div>
    )
}

export default ROISurvey