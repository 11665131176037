import {useEffect, useState} from "react";
import golden_star from "../assets/golden_star.png"
import hollow_star from "../assets/hollow_star.png"

const ROICoachPerformance = (props) => {

    const [data, setData] = useState(null)
    const stars = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]

    useEffect(() => {
        setData(props.data)
    }, [props.data])

    return (
        <div className="roi-coach-performance-container">
            <div className="roi-coach-performance-title-container">Coach Performance Summary</div>
            <div className="roi-coach-performance-body-container">
                <div className="roi-coach-performance-body-row">
                    <div className="roi-coach-performance-body-text">
                        The  following scores are the aggregated feedback scores you have received from team participants,
                        as rated at the end of the coaching engagement, for all teams selected for this report.
                    </div>
                    <div className="roi-coach-performance-body-graphic">
                        <div className="roi-coach-performance-participants-asked bold">
                            <div className="roi-coach-performance-participants-asked-text">Participants Asked</div>
                            <div className="roi-coach-performance-participants-asked-spacer"/>
                            <div className="roi-coach-performance-participants-asked-text value">
                                {data && data.participants_asked}
                            </div>
                        </div>
                    </div>
                </div>
                {data && data ?
                    <>
                        <div className="roi-coach-performance-body-row">
                            <div className="roi-coach-performance-number-container">1.</div>
                            <div className="roi-coach-performance-body-text thin regular">
                                The Team Coach(es) positively influenced the communication and effectiveness <b className="bold">of the team as a whole</b>?
                            </div>
                            <div className="roi-coach-performance-rating-container">
                                <div className="roi-coach-performance-rating-headings-container">
                                    <div className="roi-coach-performance-rating-heading">Lowest</div>
                                    <div className="roi-coach-performance-rating-heading">Highest</div>
                                </div>
                                <div className="roi-coach-performance-stars-container">
                                    {stars && stars.map((star, idx) => {
                                        return (
                                            <div className={idx===0?"roi-coach-performance-star-column-container start"
                                                :idx===9?"roi-coach-performance-star-column-container end"
                                                    :"roi-coach-performance-star-column-container"}>
                                                <img src={data.team_improvement >= star ? golden_star :hollow_star} alt={"star"}/>
                                                <div className="roi-star-text">{star}</div>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        </div>
                        <div className="roi-coach-performance-body-row">
                            <div className="roi-coach-performance-number-container">2.</div>
                            <div className="roi-coach-performance-body-text thin regular">
                                The Team Coach(es) positively influenced the communication and effectiveness of <b className="bold">you personally</b> within this team?
                            </div>
                            <div className="roi-coach-performance-rating-container">
                                <div className="roi-coach-performance-rating-headings-container">
                                    <div className="roi-coach-performance-rating-heading">Lowest</div>
                                    <div className="roi-coach-performance-rating-heading">Highest</div>
                                </div>
                                <div className="roi-coach-performance-stars-container">
                                    {stars && stars.map((star, idx) => {
                                        return (
                                            <div className={idx===0?"roi-coach-performance-star-column-container start"
                                                :idx===9?"roi-coach-performance-star-column-container end"
                                                    :"roi-coach-performance-star-column-container"}>
                                                <img src={data.personal_improvement >= star ? golden_star :hollow_star} alt={"star"}/>
                                                <div className="roi-star-text">{star}</div>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        </div>
                        <div className="roi-coach-performance-body-row">
                            <div className="roi-coach-performance-number-container">3.</div>
                            <div className="roi-coach-performance-body-text thin regular">
                                I would recommend this Team Coach(es) to another team?
                            </div>
                            <div className="roi-coach-performance-rating-container">
                                <div className="roi-coach-performance-rating-headings-container">
                                    <div className="roi-coach-performance-rating-heading">Lowest</div>
                                    <div className="roi-coach-performance-rating-heading">Highest</div>
                                </div>
                                <div className="roi-coach-performance-stars-container">
                                    {stars && stars.map((star, idx) => {
                                        return (
                                            <div className={idx===0?"roi-coach-performance-star-column-container start"
                                                :idx===9?"roi-coach-performance-star-column-container end"
                                                    :"roi-coach-performance-star-column-container"}>
                                                <img src={data.recommend >= star ? golden_star :hollow_star} alt={"star"}/>
                                                <div className="roi-star-text">{star}</div>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        </div>
                    </>
                    :null}
            </div>
        </div>
    )
}


export default ROICoachPerformance