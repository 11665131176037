import axiosInstance from "../axios/axios_instance"
const http = axiosInstance("/protected",  null, "/dashboard")


const getTeams = () => http.get("/teams");
const getTeamByID = (team_id) => http.get(`/teams/${team_id}`);
const getDemoTeamByID = (team_id) => http.get(`/demo_teams/${team_id}`)
const getTeamsByCompanyID = (id) => http.get(`/teams_by_company/${id}`)
const getDemoTeamsByCompanyID = (id) => http.get(`/demo_teams_by_company/${id}`)
const newTeam = (body) => http.post("/teams", body);
const updateTeam = (id, body) => http.put(`/teams/${id}`, body);
const deleteTeam = (TeamId) => http.delete(`teams/${TeamId}`)
const getTeamSurveys = (team_id) => http.get(`/team/surveys/${team_id}`)
const putTeamVisibility = (team_id, body) => http.put(`/team_hidden/${team_id}`, body)
const getDemoTeamSurveys = (team_id) => http.get(`/demo_team/demo_surveys/${team_id}`)
const getDemoTeams = () => http.get("/demo_teams")
const getLatestSurvey = (team_id, demo) => http.get(`/latest_survey/${team_id}/${demo}`)
const getPreviousSurveys = (team_id, demo) => http.get(`/previous_surveys/${team_id}/${demo}`)
const getTeamImprovement = (team_id, demo) => http.get(`/team_improvement/${team_id}/${demo}`)
const getTeamFeedback = (team_id) => http.get(`/teams_feedback/${team_id}`)

export default {
    getTeams,
    getTeamByID,
    getTeamsByCompanyID,
    newTeam,
    updateTeam,
    deleteTeam,
    getTeamSurveys,
    putTeamVisibility,
    getDemoTeamsByCompanyID,
    getDemoTeamByID,
    getDemoTeamSurveys,
    getDemoTeams,
    getLatestSurvey,
    getPreviousSurveys,
    getTeamImprovement,
    getTeamFeedback
}