import collapse from "../assets/collapse.svg"
import expand from "../assets/expand-teams.svg"
import edit from "../assets/teams_edit.svg"
import bin from "../assets/teams_bin.svg"
import add from "../assets/teams-add-team-member.svg"
import tick from "../assets/teams-popup-tick.svg"
import cross from "../assets/teams-popup-cross.svg"
import {useEffect, useState} from "react";


const EditTeamMembersContainer = (props) => {

    const [teamMembers, setTeamMembers] = useState([])
    const [showEditDialogue, setShowEditDialogue] = useState(false)
    const [showDeleteDialogue, setShowDeleteDialogue] = useState(false)
    const [showNewDialogue, setShowNewDialogue] = useState(false)
    const [activePerson, setActivePerson] = useState(null)
    const [editName, setEditName] = useState("")
    const [editRole, setEditRole] = useState(0)
    const [editEmail, setEditEmail] = useState("")
    const [collapsed, setCollapsed] = useState(false);

    const getRole = (id) => {
        switch (id) {
            case 1: return "Team Member"
            case 2: return "Team Leader"
            case 3: return "Stakeholder"
            default: return ""
        }
    }

    useEffect(() => {
        setTeamMembers(props.people)
    }, [props]);

    const handleOpenDelete = (ev, person) => {
        if(!ev) return;
        setActivePerson(person);
        setShowDeleteDialogue(true);
    }

    const handleCloseDelete = (ev) => {
        if(!ev) return;
        setActivePerson(null);
        setShowDeleteDialogue(false);
    }

    const deletePerson = (ev) => {
        if(!ev || !activePerson || props.isDemo) return;
        let idx = teamMembers.map(x => x.id).indexOf(activePerson.id)
        props.deletePerson(ev, idx)
        setActivePerson(null)
        setShowDeleteDialogue(false)
    }

    const handleCloseEdit = (ev) => {
        if(!ev) return;
        setShowEditDialogue(false)
    }

    const handleOpenEdit = (ev, person) => {
        if(!ev) return;
        setActivePerson(person)
        setEditName(person.name)
        setEditRole(person.role)
        setEditEmail(person.email)
        setShowEditDialogue(true);
    }

    const editPerson = (ev) => {
        if(!ev || !activePerson || props.isDemo) return;
        let idx = teamMembers.map(x => x.id).indexOf(activePerson.id)
        let person = {
            "name": editName,
            "role": editRole,
            "email": editEmail,
            "id": activePerson.id
        }
        props.updatePerson(person, idx)
        setEditEmail("")
        setEditRole(0)
        setEditName("")
        setActivePerson(null)
        setShowEditDialogue(false)
    }

    const handleOpenNew = (ev) => {
        if(!ev) return;
        setShowNewDialogue(true)
    }

    const handleCloseNew = (ev) => {
        if(!ev) return;
        setShowNewDialogue(false)
    }

    const newPerson = (ev) => {
        if(!ev || props.isDemo) return;
        let person = {
            "name": editName,
            "email": editEmail,
            "role": editRole,
            "id": "new"
        }
        setEditName("")
        setEditRole(0)
        setEditEmail("")
        props.newPerson(person)
        setShowNewDialogue(false)
    }

    const handleCollapse = (ev) => {
        if(!ev) return;
        setCollapsed(!collapsed)
    }


    return (
        <>
            <div className={`edit-team-details-container ${collapsed ? "collapsed" : ""}`}>
                <div className={`edit-team-details-top-row ${collapsed ? "collapsed" : ""}`}>
                    <div className="edit-team-details-title bold">Team Members</div>
                    <div className={`edit-team-details-collapse ${collapsed ? "collapsed" : ""}`} onClick={handleCollapse}>
                        <img src={collapsed ? expand : collapse} alt={collapsed ? "Show Team Members" : "Hide Team Members"}/>
                    </div>
                </div>
                {!collapsed ?
                    <>
                        <div className="edit-team-members-headings">
                            <div className="edit-team-details-subheading medium name">Name</div>
                            <div className="edit-team-details-subheading medium role">Role</div>
                            <div className="edit-team-details-subheading medium email">Email</div>
                        </div>
                        {teamMembers.map((team_member, idx) => {
                            return (
                                <div className="edit-team-members-team-member-row" key={idx}>
                                    <div className="team-member-row-input name">
                                        <input type="text" value={team_member.name} disabled={true}/>
                                    </div>
                                    <div className="team-member-row-input role">
                                        <input type="text" value={getRole(team_member.role)} disabled={true}/>
                                    </div>
                                    <div className="team-member-row-input email">
                                        <input type="text" value={team_member.email} disabled={true}/>
                                    </div>
                                    {!props.isDemo ?
                                        <>
                                            <div className="team-member-row-button margin"
                                                 onClick={ev => handleOpenEdit(ev, team_member)}>
                                                <img src={edit} alt="Edit"/>
                                            </div>
                                            <div className="team-member-row-button"
                                                 onClick={ev => handleOpenDelete(ev, team_member)}>
                                                <img src={bin} alt="Delete"/>
                                            </div>
                                        </> : null
                                    }
                                </div>
                            )
                        })}
                        <div className="edit-team-members-add-team-member-row" style={{display: props.isDemo ? "none" : "block"}}>
                            <div className="add-team-member-button" onClick={handleOpenNew}>
                                <img src={add} alt="Add team member"/>
                            </div>
                            <div className="add-team-member-text bold" onClick={handleOpenNew}>ADD TEAM MEMBER</div>
                        </div>
                    </> : null
                }
            </div>
            {showDeleteDialogue ?
                <>
                    <div className="page-mask" onClick={handleCloseDelete}/>
                    <div className="edit-team-members-delete-popup">
                        <div className="edit-team-members-delete-popup-text bold">Are you sure you want to delete</div>
                        <div className="edit-team-members-delete-popup-name regular">
                            {activePerson && activePerson.name}
                        </div>
                        <div className="edit-team-members-delete-popup-controls" onClick={deletePerson}>
                            <div className="edit-team-members-delete-popup-button">
                                <img src={tick} alt="Accept"/>
                            </div>
                            <div className="edit-team-members-delete-popup-button" onClick={handleCloseDelete}>
                                <img src={cross} alt="Cancel"/>
                            </div>
                        </div>
                    </div>
                </> : null
            }
            {showEditDialogue ?
                <>
                    <div className="page-mask" onClick={handleCloseEdit}/>
                    <div className="edit-team-members-edit-popup">
                        <div className="edit-team-members-edit-popup-title bold">Edit Team Member</div>
                        <div className="edit-team-members-edit-popup-input medium">
                            <input type="text" onChange={ev => setEditName(ev.target.value)} value={editName}/>
                        </div>
                        <div className="edit-team-members-edit-popup-input medium">
                            <select onChange={ev => setEditRole(ev.target.value)} value={editRole}>
                                <option value={1}>Team Member</option>
                                <option value={2}>Team Leader</option>
                                <option value={3}>Stakeholder</option>
                            </select>
                        </div>
                        <div className="edit-team-members-edit-popup-input medium">
                            <input type="text" onChange={ev => setEditEmail(ev.target.value)} value={editEmail}/>
                        </div>
                        <div className="edit-team-members-edit-popup-controls-container">
                            <div className="edit-team-members-edit-popup-button bold" onClick={handleCloseEdit}>
                                CANCEL
                            </div>
                            <div className="edit-team-members-edit-popup-button filled bold" onClick={editPerson}>
                                UPDATE
                            </div>
                        </div>
                    </div>
                </> : null
            }
            {showNewDialogue ?
                <>
                    <div className="page-mask" onClick={handleCloseNew}/>
                    <div className="edit-team-members-edit-popup">
                        <div className="edit-team-members-edit-popup-title bold">New Team Member</div>
                        <div className="edit-team-members-edit-popup-input medium">
                            <input type="text" onChange={ev => setEditName(ev.target.value)} placeholder="Name"
                                   value={editName}/>
                        </div>
                        <div className="edit-team-members-edit-popup-input medium">
                            <select onChange={ev => setEditRole(ev.target.value)} value={editRole}>
                                <option value={1}>Team Member</option>
                                <option value={2}>Team Leader</option>
                                <option value={3}>Stakeholder</option>
                            </select>
                        </div>
                        <div className="edit-team-members-edit-popup-input medium">
                            <input type="text" onChange={ev => setEditEmail(ev.target.value)} placeholder="Email"
                                   value={editEmail}/>
                        </div>
                        <div className="edit-team-members-edit-popup-controls-container">
                            <div className="edit-team-members-edit-popup-button bold" onClick={handleCloseNew}>
                                CANCEL
                            </div>
                            <div className="edit-team-members-edit-popup-button filled bold" onClick={newPerson}>
                                ADD
                            </div>
                        </div>
                    </div>
                </> : null
            }
        </>
    )
}


export default EditTeamMembersContainer