import React, {useEffect, useState} from "react";
import {Route, Routes} from "react-router-dom";
import Forbidden from "../pages/Forbidden";
import MainHeader from "./MainHeader";
import HomeHeader from "./HomeHeader";
import HomeFooter from "./HomeFooter";
import Login from "../pages/Login";
import ForgotPassword from "../pages/ForgotPassword";
import ResetPassword from "../pages/ResetPassword";
import Unknown from "../pages/Unknown";
import FooterContainer from "./FooterContainer";
import Register from "../pages/Register";
import Companies from "../pages/Companies";
import Teams from "../pages/Teams";
import Surveys from "../pages/Surveys";
import Reports from "../pages/Reports";
import Dashboard from "../pages/Dashboard";
import Settings from "../pages/Settings";
import EditTeam from "../pages/EditTeam";
import EditCompany from "../pages/EditCompany";
import Questionnaire from "../pages/Questionnaire";
import Home from "../pages/Home"
import LearnMoreProfessional from "../pages/LearnMoreProfessional";
import LearnMoreCoaches from "../pages/LearnMoreCoaches";
import LearnMoreEnterprise from "../pages/LearnMoreEnterprise";
import Logout from "../pages/Logout";
import LoggedOut from "../pages/LoggedOut";
import Error from "../pages/Error";
import PerillModel from "../pages/PerillModel";
import PerillResources from "../pages/PerillResources";
import AboutCCMI from "../pages/AboutCCMI";
import SurveyOverview from "../pages/SurveyOverview";
import TeamCoaching from "../pages/TeamCoaching";
import GetInTouch from "../pages/GetInTouch";
import PricingPlans from "../pages/PricingPlans";
import SearchPage from "../pages/SearchPage";
import PerillHowItWorks from "../pages/PerillHowItWorks";
import EditSurvey from "../pages/EditSurvey";
import EditCoCoach from "../pages/EditCoCoach";
import ReportConfigurator from "../pages/ReportConfigurator";
import TermsOfUse from "../pages/TermsOfUse";
import PrivacyPolicy from "../pages/PrivacyPolicy";
import FAQ from "../pages/FAQ";
import Unsubscribe from "../pages/Unsubscribe";
import StripeSuccess from "../pages/StripeSuccess";
import SettingsFlowHandler from "../pages/SettingsFlowHandler"
import Notifications from "../pages/Notifications"
import PasswordChanged from "../pages/PasswordChanged";
import ROIReport from "../pages/ROIReport";
import DiagnosticEngagement from "../pages/DiagnosticEngagement";


const AppLayout = () => {
    const [cookies, setCookies] = useState('')

    useEffect(() => {
        setCookies('1')
    }, []);

    // GOOGLE ANALYTICS CONSENT MODE
    useEffect(() => {
        let consent;
        if(cookies === "1") {
            consent = {
                'ad_storage': "granted",
                'analytics_storage': "granted",
                'functionality_storage': "granted",
                'personalization_storage': "granted",
                'security_storage': "granted",
            }
        }
        else {
            consent = {
                'ad_storage': "denied",
                'analytics_storage': "denied",
                'functionality_storage': "denied",
                'personalization_storage': "denied",
                'security_storage': "denied",
            }
        }
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push('consent', 'update', consent);
    }, [cookies])

    // GOOGLE TAG ANALYTICS
    useEffect(() => {
        let google_tag = process.env.REACT_APP_GOOGLE_TAG
        if (!google_tag) return
        let base_script = document.createElement('script');
        base_script.type = 'text/javascript';
        base_script.src = 'https://www.googletagmanager.com/gtag/js?id=' + google_tag;
        base_script.async = true;
        document.head.appendChild(base_script);

        let script = document.createElement('script');
        script.type = 'text/javascript';
        script.text =
            'window.dataLayer = window.dataLayer || [];\n'+
            'function gtag(){dataLayer.push(arguments);}\n'+
            'gtag(\'js\', new Date());\n'+
            'gtag(\'config\', \'' + google_tag + '\');'
        document.head.appendChild(script);
    }, [])

    // hot-jar
    useEffect(() => {
        let hotjar_id = process.env.REACT_APP_HOTJAR_ID
        if (!hotjar_id) return
        if (cookies === "1"){
            let script = document.createElement('script');
            script.type = 'text/javascript';
            script.text =
                '               (function(h,o,t,j,a,r){\n' +
                '                    h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};\n' +
                '                    h._hjSettings={hjid:' + hotjar_id + ',hjsv:6};\n' +
                '                    a=o.getElementsByTagName(\'head\')[0];\n' +
                '                    r=o.createElement(\'script\');r.async=1;\n' +
                '                    r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;\n' +
                '                    a.appendChild(r);\n' +
                '                })(window,document,\'https://static.hotjar.com/c/hotjar-\',\'.js?sv=\');';
            document.head.appendChild(script);
        }
    }, [cookies])

    return (
        <>
            <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css" rel="stylesheet"
                  integrity="sha384-EVSTQN3/azprG1Anm3QDgpJLIm9Nao0Yz1ztcQTwFspd3yD65VohhpuuCOmLASjC"
                  crossOrigin="anonymous"/>
            <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/js/bootstrap.bundle.min.js"
                    integrity="sha384-MrcW6ZMFYlzcLA8Nl+NtUVF0sA7MsXsP1UyJoMp4YLEuNSfAP+JcXn/tWtIaxVXM"
                    crossOrigin="anonymous" />

            <Routes>
                <Route exact path="/" element={
                    <>
                        <HomeHeader />
                        <Home />
                        <HomeFooter />
                    </>
                }/>
                <Route path="/privacy" element={
                    <>
                        <HomeHeader />
                        <PrivacyPolicy />
                        <HomeFooter />
                    </>
                }/>
                <Route path="/faq" element={
                    <>
                        <HomeHeader />
                        <FAQ />
                        <HomeFooter />
                    </>
                }/>
                <Route path="/terms-of-use" element={
                    <>
                        <HomeHeader />
                        <TermsOfUse />
                        <HomeFooter />
                    </>
                }/>
                <Route exact path="/what-is-the-perill-model" element={
                    <>
                        <HomeHeader />
                        <PerillModel />
                        <HomeFooter />
                    </>
                }/>
                <Route exact path="/pricing-plans" element={
                    <>
                        <HomeHeader />
                        <PricingPlans />
                        <HomeFooter removeButton={true}/>
                    </>
                }/>
                <Route exact path="/get-in-touch" element={
                    <>
                        <HomeHeader />
                        <GetInTouch />
                        <HomeFooter hide_banner={true}/>
                    </>
                }/>
                <Route exact path="/team-coaching" element={
                    <>
                        <HomeHeader />
                        <TeamCoaching />
                        <HomeFooter />
                    </>
                }/>
                <Route exact path="/about-ccmi" element={
                    <>
                        <HomeHeader />
                        <AboutCCMI />
                        <HomeFooter />
                    </>
                }/>
                <Route exact path="/perill-how-it-works" element={
                   <>
                       <HomeHeader />
                       <PerillHowItWorks />
                       <HomeFooter />
                   </>
                }/>
                <Route exact path="/perill-resources" element={
                    <>
                        <HomeHeader />
                        <PerillResources />
                        <HomeFooter />
                    </>
                }/>
                <Route exact path="/dashboard" element={
                    <>
                        <MainHeader />
                        <Dashboard />
                        <FooterContainer />
                    </>
                }/>
                <Route path="/line-managers-and-hr-professionals" element={
                    <>
                        <HomeHeader />
                        <LearnMoreProfessional />
                        <HomeFooter />
                    </>
                }/>
                <Route path="/team-coaches" element={
                    <>
                        <HomeHeader />
                        <LearnMoreCoaches />
                        <HomeFooter />
                    </>
                }/>
                <Route path="/enterprise" element={
                    <>
                        <HomeHeader />
                        <LearnMoreEnterprise />
                        <HomeFooter />
                    </>
                }/>
                <Route exact path="/unsubscribe" element={
                    <>
                        <HomeHeader />
                        <Unsubscribe />
                        <HomeFooter />
                    </>
                }/>
                <Route path="/companies" element={
                    <>
                        <MainHeader />
                        <Companies />
                        <FooterContainer />
                    </>
                }/>
                <Route path="/companies/:companyId" element={
                    <>
                        <MainHeader />
                        <EditCompany />
                        <FooterContainer />
                    </>
                }/>
                <Route path="/teams" element={
                    <>
                        <MainHeader />
                        <Teams />
                        <FooterContainer />
                    </>
                }/>
                <Route path="/teams/:teamId" element={
                    <>
                        <MainHeader />
                        <EditTeam />
                        <FooterContainer />
                    </>
                }/>
                <Route path="/surveys" element={
                    <>
                        <MainHeader />
                        <Surveys />
                        <FooterContainer />
                    </>
                }/>
                <Route path="/surveys/:surveyId" element={
                    <>
                        <MainHeader />
                        <EditSurvey />
                        <FooterContainer />
                    </>
                }/>
                <Route path="/report-configurator/:surveyId" element={
                    <>
                        <MainHeader />
                        <ReportConfigurator />
                        <FooterContainer />
                    </>
                }/>
                <Route path="/search" element={
                    <>
                        <MainHeader />
                        <SearchPage />
                        <FooterContainer />
                    </>
                }/>
                <Route path="/diagnostic_summary/:surveyId" element={
                    <>
                        <MainHeader />
                        <SurveyOverview />
                        <FooterContainer />
                    </>
                }/>
                <Route path="/reports" element={
                    <>
                        <MainHeader />
                        <Reports />
                        <FooterContainer />
                    </>
                }/>
                <Route path="/notifications" element={
                    <>
                        <MainHeader />
                        <Notifications />
                        <FooterContainer />
                    </>
                }/>
                <Route path="/login" element={
                    <>
                        <HomeHeader />
                        <Login />
                        <FooterContainer />
                    </>
                }/>
                <Route path="/forgot-password" element={
                    <>
                        <HomeHeader />
                        <ForgotPassword />
                        <FooterContainer />
                    </>
                }/>
                <Route path="/reset-password" element={
                    <>
                        <HomeHeader />
                        <ResetPassword />
                        <FooterContainer />
                    </>
                }/>
                <Route path="/password-changed" element={
                    <>
                        <HomeHeader />
                        <PasswordChanged />
                        <FooterContainer />
                    </>
                }/>
                <Route path="/logout" element={
                    <>
                        <Logout />
                    </>
                }/>
                <Route path="/settings-flow-handler" element={
                    <>
                        <SettingsFlowHandler />
                    </>
                }/>
                <Route path="/error" element={
                    <>
                        <HomeHeader />
                        <Error />
                        <FooterContainer />
                    </>
                }/>
                <Route path="/logged_out" element={
                    <>
                        <HomeHeader />
                        <LoggedOut />
                        <FooterContainer />
                    </>
                }/>
                <Route path="/register" element={
                    <>
                        <HomeHeader />
                        <Register />
                        <FooterContainer />
                    </>

                }/>
                <Route path="/settings" element={
                    <>
                        <MainHeader />
                        <Settings />
                        <FooterContainer />
                    </>
                }/>
                <Route path="/co-coach/:coCoachId" element={
                    <>
                        <MainHeader />
                        <EditCoCoach />
                        <FooterContainer />
                    </>
                }/>
                <Route path="/questionnaire" element={
                    <Questionnaire />
                }/>
                <Route path="/forbidden" element={
                    <>
                        <HomeHeader />
                        <Forbidden />
                        <FooterContainer />
                    </>
                }/>
                <Route path="/success" element={
                    <>
                        <HomeHeader />
                        <StripeSuccess />
                        <HomeFooter />
                    </>
                }/>
                <Route path="/roireport" element={
                    <>
                        <MainHeader />
                        <ROIReport />
                        <FooterContainer />
                    </>
                }/>
                <Route path="/roireport/:reportId" element={
                    <>
                        <MainHeader />
                        <ROIReport />
                        <FooterContainer />
                    </>
                }/>
                <Route path="/diagnostic_engagement/:firstSurveyId/:secondSurveyId" element={
                    <>
                        <MainHeader />
                        <DiagnosticEngagement />
                        <FooterContainer />
                    </>
                }/>
                <Route path="*" element={
                    <>
                        <HomeHeader />
                        <Unknown />
                        <FooterContainer />
                    </>
                }/>
            </Routes>


        </>

    )
}

export default AppLayout;