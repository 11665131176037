import individual from "../assets/individual-effectiveness.svg"
import team from "../assets/team-effectiveness.svg"
import recommended from "../assets/recommended-thumb.svg"
import filled_star from "../assets/teams-filled-star.svg"
import empty_star from "../assets/teams-empty-star.svg"
import teams_api from "../api/protected/teams"
import {useEffect, useState} from "react";


const EditTeamFeedbackContainer = (props) => {

    const [feedback, setFeedback] = useState(null)
    const starMap = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]

    const getTeamFeedback = () => {
        let response = teams_api.getTeamFeedback(props.team_id);
        response.then(response => {
            setFeedback(response.data)
            console.log(response.data)
        }).catch(err => console.log(err))
    }

    useEffect(() => {
        if(!props.team_id) return;
        getTeamFeedback()
    }, [props.team_id]);

    const calculateIncrease = () => {
        if(!feedback) return;
        if(!feedback.initial_avg) return feedback.endpoint_avg
        let increase = feedback.endpoint_avg - feedback.initial_avg
        return Math.round((increase / feedback.initial_avg) * 100)
    }


    return (
        <div className="edit-team-feedback-container" style={{display: props.isDemo ? "none" : "block"}}>
            <div className="edit-team-feedback-main">
                <div className="edit-team-feedback-left">
                    <div className="edit-team-feedback-title margined bold">Feedback Ratings</div>
                    <div className="edit-team-feedback-rating-container">
                        <div className="edit-team-feedback-rating-icon">
                            <img src={individual} alt="Individual improvement"/>
                        </div>
                        <div className="edit-team-feedback-rating-main">
                            <div className="edit-team-feedback-rating-title regular">
                                <span className="bold">TEAM</span> EFFECTIVENESS IMPROVEMENT
                            </div>
                            <div className="edit-team-feedback-rating-stars-container">
                                {starMap.map(idx => {
                                    return (
                                        <div className="feedback-rating-star-container" key={idx}>
                                            <div className="feedback-rating-star">
                                                {feedback?.question_response_1 >= idx ?
                                                    <img src={filled_star} alt="Star"/>
                                                    :
                                                    <img src={empty_star} alt="Star"/>
                                                }
                                            </div>
                                            <div className="feedback-rating-number bold">{idx}</div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                    <div className="edit-team-feedback-rating-container">
                        <div className="edit-team-feedback-rating-icon">
                            <img src={team} alt="Individual improvement"/>
                        </div>
                        <div className="edit-team-feedback-rating-main">
                            <div className="edit-team-feedback-rating-title regular">
                                <span className="bold">INDIVIDUAL</span> EFFECTIVENESS IMPROVEMENT
                            </div>
                            <div className="edit-team-feedback-rating-stars-container">
                                {starMap.map(idx => {
                                    return (
                                        <div className="feedback-rating-star-container" key={idx}>
                                            <div className="feedback-rating-star">
                                                {feedback?.question_response_2 >= idx ?
                                                    <img src={filled_star} alt="Star"/>
                                                    :
                                                    <img src={empty_star} alt="Star"/>
                                                }
                                            </div>
                                            <div className="feedback-rating-number bold">{idx}</div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                    <div className="edit-team-feedback-rating-container">
                        <div className="edit-team-feedback-rating-icon">
                            <img src={recommended} alt="Individual improvement"/>
                        </div>
                        <div className="edit-team-feedback-rating-main">
                            <div className="edit-team-feedback-rating-title regular">RECOMMENDED</div>
                            <div className="edit-team-feedback-rating-stars-container">
                                {starMap.map(idx => {
                                    return (
                                        <div className="feedback-rating-star-container" key={idx}>
                                            <div className="feedback-rating-star">
                                                {feedback?.question_response_3 >= idx ?
                                                    <img src={filled_star} alt="Star"/>
                                                    :
                                                    <img src={empty_star} alt="Star"/>
                                                }
                                            </div>
                                            <div className="feedback-rating-number bold">{idx}</div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="edit-team-feedback-right">
                    <div className="roi-report-title-card-improvement-container small">
                        <div className="roi-report-title-card-heading no-decoration">Teams Overall Performance</div>
                        <div className="roi-report-title-card-improvement-body-container">
                            <div className="roi-report-title-card-improvement-left-half">
                                <div className="roi-report-title-card-improvement-bar-container title-card">
                                    <div className="roi-title-card-graphic-text">START OF ENGAGEMENT</div>
                                    <div className="roi-coach-performance-body-graphic title-card">
                                        <div className="roi-coach-performance-bar">
                                            <div className="roi-coach-peformance-bar-graphic">
                                                <div className="roi-coach-performance-bar-white-band">
                                                    <div className={`roi-coach-performance-bar-point title-card ${feedback && feedback.initial_avg < 25 ? "low" : ""}`}
                                                         style={{left: `calc(${feedback && feedback.initial_avg * 2}% - 17.5px)`}}>
                                                        {feedback && feedback.initial_avg}
                                                    </div>
                                                    <div className="roi-coach-performance-bar-colour zero"/>
                                                    <div className="roi-coach-performance-bar-spacer"/>
                                                    <div className="roi-coach-performance-bar-colour ten"/>
                                                    <div className="roi-coach-performance-bar-spacer"/>
                                                    <div className="roi-coach-performance-bar-colour twenty"/>
                                                    <div className="roi-coach-performance-bar-spacer"/>
                                                    <div className="roi-coach-performance-bar-colour thirty"/>
                                                    <div className="roi-coach-performance-bar-spacer"/>
                                                    <div className="roi-coach-performance-bar-colour forty"/>
                                                </div>
                                            </div>
                                            <div className="roi-coach-performance-bar-text-container bold title-card">
                                                <div className="roi-coach-performance-bar-text">0</div>
                                                <div className="roi-coach-performance-bar-text">10</div>
                                                <div className="roi-coach-performance-bar-text">20</div>
                                                <div className="roi-coach-performance-bar-text">30</div>
                                                <div className="roi-coach-performance-bar-text">40</div>
                                                <div className="roi-coach-performance-bar-text">50</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="roi-title-card-vs-container">VS</div>
                                <div className="roi-report-title-card-improvement-bar-container title-card">
                                    <div className="roi-title-card-graphic-text">END OF ENGAGEMENT</div>
                                    <div className="roi-coach-performance-body-graphic title-card">
                                        <div className="roi-coach-performance-bar">
                                            <div className="roi-coach-peformance-bar-graphic">
                                                <div className="roi-coach-performance-bar-white-band">
                                                    <div className={`roi-coach-performance-bar-point title-card ${feedback && feedback.endpoint_avg < 25 ? "low" : ""}`}
                                                        style={{left: `calc(${feedback && feedback.endpoint_avg * 2}% - 17.5px)`}}>
                                                        {feedback && feedback.endpoint_avg}
                                                    </div>
                                                    <div className="roi-coach-performance-bar-colour zero"/>
                                                    <div className="roi-coach-performance-bar-spacer"/>
                                                    <div className="roi-coach-performance-bar-colour ten"/>
                                                    <div className="roi-coach-performance-bar-spacer"/>
                                                    <div className="roi-coach-performance-bar-colour twenty"/>
                                                    <div className="roi-coach-performance-bar-spacer"/>
                                                    <div className="roi-coach-performance-bar-colour thirty"/>
                                                    <div className="roi-coach-performance-bar-spacer"/>
                                                    <div className="roi-coach-performance-bar-colour forty"/>
                                                </div>
                                            </div>
                                            <div className="roi-coach-performance-bar-text-container bold title-card">
                                                <div className="roi-coach-performance-bar-text">0</div>
                                                <div className="roi-coach-performance-bar-text">10</div>
                                                <div className="roi-coach-performance-bar-text">20</div>
                                                <div className="roi-coach-performance-bar-text">30</div>
                                                <div className="roi-coach-performance-bar-text">40</div>
                                                <div className="roi-coach-performance-bar-text">50</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="roi-report-title-card-improvement-right-half">
                                <div className="roi-title-card-graphic-text">PERFORMANCE % CHANGE</div>
                                {feedback && feedback.endpoint_avg > feedback.initial_avg ? <div className="roi-report-title-card-tick-box">✓</div> : null}
                                <div className="roi-report-title-card-improvement-text">
                                    {calculateIncrease()}<span>%</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="edit-team-feedback-title bold">Latest Feedback Reviews</div>
            <div className="edit-team-feedback-response-container">
                <div className="edit-team-feedback-question regular">
                    What would have made the team coaching experience more valuable for you?
                </div>
                <div className="edit-team-feedback-response regular">{feedback && feedback.question_response_4}</div>
            </div>
            <div className="edit-team-feedback-response-container">
                <div className="edit-team-feedback-question regular">
                    What was the most confusing or challenging part of the team coaching experience?
                </div>
                <div className="edit-team-feedback-response regular">{feedback && feedback.question_response_5}</div>
            </div>
            <div className="edit-team-feedback-response-container">
                <div className="edit-team-feedback-question regular">
                    Is there anything else you think we should know?
                </div>
                <div className="edit-team-feedback-response regular">{feedback && feedback.question_response_6}</div>
            </div>
            <div className="edit-team-feedback-button-container">
                <div className="edit-team-feedback-button bold">SEE ALL REVIEWS</div>
            </div>
        </div>
    )
}


export default EditTeamFeedbackContainer