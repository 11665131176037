import tick from "../../assets/tasks-tick.svg"
import edit from "../../assets/tasks-edit.svg"
import del from "../../assets/tasks-delete.svg"
import calendar from "../../assets/tasks-date.svg"
import del_tick from "../../assets/settings-tick.svg"
import del_cross from "../../assets/settings-cross.svg"
import link from "../../assets/tasks-link.svg"
import {useEffect, useState} from "react";
import dashboard_api from "../../api/protected/dashboard";
import {DayPicker} from "react-day-picker";
import {useNavigate} from "react-router-dom";


const TasksCard = (props) => {

    const navigate = useNavigate()
    const [tasks, setTasks] = useState([])
    const [showTaskPopup, setShowTaskPopup] = useState(false)
    const [showDeletePopup, setShowDeletePopup] = useState(false)
    const [showTaskViewPopup, setShowTaskViewPopup] = useState(false)
    const [taskTitle, setTaskTitle] = useState("")
    const [taskDescription, setTaskDescription] = useState("")
    const [taskDate, setTaskDate] = useState(null)
    const [datepickerDate, setDatepickerDate] = useState("")
    const [taskChecked, setTaskChecked] = useState(false)
    const [selectedTask, setSelectedTask] = useState(null)
    const [filterState, setFilterState] = useState(0)
    const [showCalendar, setShowCalendar] = useState(false)

    useEffect(() => {
        getTasks()
    }, []);

    const getTasks = () => {
        let response = dashboard_api.getTodoList();
        response.then(response => {
            setTasks(response.data)
        }).catch(err => console.log(err))
    }

    const saveTask = (ev) => {
        if(!ev) return;

        let body = {
            "title": taskTitle,
            "description": taskDescription,
            "date": taskDate,
            "checked": taskChecked
        }

        let response;

        if(selectedTask) response = dashboard_api.editTodo(selectedTask.id, body);
        else response = dashboard_api.newTodo(body);

        response.then(() => {
            getTasks()
        }).catch(err => console.log(err))

        setSelectedTask(null)
        setTaskTitle("")
        setTaskDescription("")
        setTaskDate(0)
        setTaskChecked(false)
        setShowTaskPopup(false)
    }

    const deleteTask = (ev) => {
        if(!ev) return;
        let response = dashboard_api.deleteTodo(selectedTask.id);
        response.then(() => {
            getTasks()
        }).catch(err => console.log(err))

        setSelectedTask(null)
        setTaskTitle("")
        setTaskDescription("")
        setTaskDate(0)
        setTaskChecked(false)
        setShowDeletePopup(false)
    }

    const toggleCheckmark = (ev, task) => {
        if(!ev) return;
        let body = {
            "title": task.title,
            "description": task.description,
            "date": task.date,
            "checked": !task.checked
        }

        let response = dashboard_api.editTodo(task.id, body);
        response.then(() => {
            getTasks()
        }).catch(err => console.log(err))
    }

    const scrollSync = (ev) => {
        if(!ev) return;
        let handleMax = 390
        let height = 427
        let scrollHeight = ev.target.scrollHeight - height
        let multiplier = 0

        if (ev.target.scrollTop) {
            multiplier = (ev.target.scrollTop / scrollHeight)
        }

        let elem = document.getElementById('dashboard-scrollbar-handle')
        if (elem) {
            elem.style.top = `${Math.round(handleMax * multiplier)}px`
        }
    }

    const handleCloseTask = (ev) => {
        if(!ev) return;
        setShowTaskPopup(false)
        setTaskTitle("")
        setTaskDescription("")
        setTaskDate(0)
        setSelectedTask(null)
    }

    const handleOpenNewTask = (ev) => {
        if(!ev) return;
        setShowTaskPopup(true)
    }

    const handleOpenEditTask = (ev, task) => {
        if(!ev || task.link) return;
        setSelectedTask(task)
        setTaskTitle(task.title)
        setTaskDescription(task.description)
        setTaskDate(task.date)
        setShowTaskPopup(true)
    }

    const handleDeleteTask = (ev, task) => {
        if(!ev) return;
        setSelectedTask(task)
        setShowDeletePopup(true)
    }

    const handleCloseDeleteTask = (ev) => {
        if(!ev) return;
        setSelectedTask(null)
        setShowDeletePopup(false)
    }

    const formatDate = (ts) => {
        if (!ts) return ""
        let date = new Date(ts * 1000)
        return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`
    }

    const setDate = (ev) => {
        if(!ev) return;
        setDatepickerDate(ev)
        let ts = ev.getTime()
        setTaskDate(ts / 1000)
        setShowCalendar(false)
    }

    const getTaskStatus = (task, classname=false) => {
        let status = ""
        let date = new Date().getTime() / 1000
        if (task.checked) status = "Complete";
        else if (task.date - date < 604800 && date < task.date) status = "Due";
        else if (task.date && task.date < date) status = "Overdue";
        else status = "Open";

        if (classname) status = status.toLowerCase()
        return status
    }

    const handleOpenTaskView = (ev, task) => {
        if(!ev) return;
        if(ev.target.id) return;
        setSelectedTask(task)
        setShowTaskViewPopup(true)
    }

    const handleCloseTaskView = (ev) => {
        if(!ev) return;
        setSelectedTask(null)
        setShowTaskViewPopup(false)
    }

    const TaskRow = (props) => {
        const task = props.task

        return (
            <div className="dashboard-task-row" key={props.idx} onClick={ev => handleOpenTaskView(ev, task)}>
                <div className={`dashboard-task-colour-strip ${task.link ? "" : "purple"}`}/>
                <div className={`tasks-radio-button green ${task.checked ? "checked" : ""}`} onClick={ev => toggleCheckmark(ev, task)}
                     id={`task-checkmark-${props.idx}`}>
                    <img src={tick} alt="Checked" id={`task-checkmark-img-${props.idx}`}/>
                </div>
                <div className="dashboard-task-title regular">{task.title}</div>
                <div className="dashboard-task-description regular">{task.description}</div>
                <div className="dashboard-task-date regular">{task.date ? formatDate(task.date) : "-"}</div>
                <div className="dashboard-task-status">
                    <div className={`dashboard-task-status-value regular ${getTaskStatus(task, true)}`}>
                        {getTaskStatus(task)}
                    </div>
                </div>
                <div className="dashboard-task-button" id={`task-edit-button-${props.idx}`}>
                    <div className={`dashboard-task-button-circle ${task.link ? "disabled" : ""}`}
                         id={`task-edit-button-circle-${props.idx}`} onClick={ev => handleOpenEditTask(ev, task)}>
                        <img src={edit} alt="Edit task" id={`task-edit-button-img-${props.idx}`}/>
                    </div>
                </div>
                <div className="dashboard-task-button" id={`task-delete-button-${props.idx}`}>
                    <div className="dashboard-task-button-circle" id={`task-delete-button-circle-${props.idx}`}
                         onClick={ev => handleDeleteTask(ev, task)}>
                        <img src={del} alt="Delete task" id={`task-delete-button-img-${props.idx}`}/>
                    </div>
                </div>
                {task.checked ? <div className="dashboard-task-complete-strikethrough"/> : null}
            </div>
        )
    }

    return (
        <>
            <div className="dashboard-tasks-container">
                <div className="dashboard-tasks-top-row">
                    <div className="dashboard-tasks-title-controls-container">
                        <div className="dashboard-tasks-card-title bold">Tasks</div>
                        <div className="dashboard-task-filter-container">
                            <div className="tasks-radio-container">
                                <div className={`tasks-radio-button grey ${filterState === 0 ? "checked" : ""}`}
                                     onClick={() => setFilterState(0)}>
                                    <img src={tick} alt="Checked"/>
                                </div>
                                <div className="tasks-radio-text">All</div>
                            </div>
                            <div className="tasks-radio-container">
                                <div className={`tasks-radio-button orange ${filterState === 1 ? "checked" : ""}`}
                                     onClick={() => setFilterState(1)}>
                                    <img src={tick} alt="Checked"/>
                                </div>
                                <div className="tasks-radio-text">Automated Tasks</div>
                            </div>
                            <div className="tasks-radio-container">
                                <div className={`tasks-radio-button purple ${filterState === 2 ? "checked" : ""}`}
                                     onClick={() => setFilterState(2)}>
                                    <img src={tick} alt="Checked"/>
                                </div>
                                <div className="tasks-radio-text">Reminders</div>
                            </div>
                        </div>
                    </div>
                    <div className="dashboard-tasks-create-task-button bold" onClick={handleOpenNewTask}>
                        CREATE TASK +
                    </div>
                </div>
                <div className="dashboard-tasks-table-headers">
                    <div className="dashboard-task-table-header task-name">Task name</div>
                    <div className="dashboard-task-table-header description">Description</div>
                    <div className="dashboard-task-table-header-container date">
                        <div className="dashboard-task-table-header">Due date</div>
                        <div className="dashboard-task-sort-controls-container"></div>
                    </div>
                    <div className="dashboard-task-table-header status">Status</div>
                    <div className="dashboard-task-table-header button">Edit</div>
                    <div className="dashboard-task-table-header button">Delete</div>
                </div>
                <div className="dashboard-tasks-table-container">
                    <div className="dashboard-tasks-scroll-container" onScroll={scrollSync}>
                        {tasks.map((task, idx) => {
                            if (filterState === 1 && task.link) {
                                return <TaskRow task={task} idx={idx}/>
                            } else if (filterState === 2 && !task.link) {
                                return <TaskRow task={task} idx={idx}/>
                            } else if (!filterState) {
                                return <TaskRow task={task} idx={idx}/>
                            }
                        })}
                    </div>
                    {tasks.length > 6 ?
                        <div className="dashboard-tasks-scrollbar">
                            <div className="dashboard-tasks-scrollbar-handle" id="dashboard-scrollbar-handle"/>
                        </div>
                        : null
                    }
                </div>
            </div>
            {showTaskPopup ?
                <>
                    <div className="page-mask" onClick={handleCloseTask}/>
                    <div className="dashboard-new-task-popup">
                        <div className="dashboard-new-task-colour-band"/>
                        <div className="dashboard-new-task-popup-container">
                            <div className="dashboard-new-task-title bold">Reminder<span>{selectedTask ? " - Edit" : " - New Task"}</span></div>
                            <div className="dashboard-new-task-heading bold">Title</div>
                            <div className="dashboard-new-task-heading-input">
                                <input type="text" value={taskTitle} onChange={ev => setTaskTitle(ev.target.value)}/>
                            </div>
                            <div className="dashboard-new-task-heading bold description">Description</div>
                            <div className="dashboard-new-task-heading-input">
                                <textarea value={taskDescription} onChange={ev => setTaskDescription(ev.target.value)}/>
                            </div>
                            <div className="dashboard-new-task-due-date-container">
                                <div className="dashboard-new-task-due-date-text medium">Due date</div>
                                <div className="dashboard-new-task-due-date-display">
                                    <div className="dashboard-new-task-date">{formatDate(taskDate)}</div>
                                    <div className="dashboard-new-task-date-picker">
                                        {showCalendar ? <DayPicker selected={datepickerDate} onDayClick={setDate} /> : null}
                                        <img src={calendar} alt="Select date" onClick={() => setShowCalendar(!showCalendar)}/>
                                    </div>
                                </div>
                            </div>
                            <div className="dashboard-new-task-popup-controls-container">
                                <div className="dashboard-new-task-button bold filled" onClick={saveTask}>SAVE</div>
                                <div className="dashboard-new-task-button bold" onClick={handleCloseTask}>CANCEL</div>
                            </div>
                        </div>
                    </div>
                </> : null
            }
            {showDeletePopup ?
                <>
                    <div className="page-mask" onClick={handleCloseDeleteTask}/>
                    <div className="dashboard-delete-task-popup">
                        <div className="dashboard-delete-task-text bold">Are you sure you want to delete</div>
                        <div className="dashboard-delete-task-type bold">Reminder</div>
                        <div className="dashboard-delete-task-title bold">{selectedTask?.title}</div>
                        <div className="dashboard-delete-task-controls-container">
                            <div className="dashboard-delete-task-button" onClick={deleteTask}>
                                <img src={del_tick} alt="Yes"/>
                            </div>
                            <div className="dashboard-delete-task-button" onClick={handleCloseDeleteTask}>
                                <img src={del_cross} alt="No"/>
                            </div>
                        </div>
                    </div>
                </> : null
            }
            {showTaskViewPopup ?
                <>
                    <div className="page-mask" onClick={handleCloseTaskView}/>
                    <div className="dashboard-task-view-popup">
                        <div className={`dashboard-new-task-colour-band ${selectedTask?.link ? "orange" : ""}`}/>
                        <div className="dashboard-task-view-contents">
                            <div className={`dashboard-task-view-task-header bold ${selectedTask?.link ? "orange" : ""}`}>
                                Task
                            </div>
                            <div className="dashboard-task-view-task-title bold">{selectedTask?.title}</div>
                            <div className="dashboard-task-view-task-description-header bold">Description</div>
                            <div className="dashboard-task-view-task-description regular">{selectedTask?.description}</div>
                            {selectedTask?.link ?
                                <div className="dashboard-task-view-link-container">
                                    <div className="dashboard-task-view-link-circle"
                                         onClick={() => navigate(`/${selectedTask?.link}`, {replace: true})}>
                                        <img src={link} alt="Summary"/>
                                    </div>
                                    <div className="dashboard-task-view-link-text semi-bold"
                                         onClick={() => navigate(`/${selectedTask?.link}`, {replace: true})}>
                                        Click for summary
                                    </div>
                                </div> : null
                            }
                            <div className="dashboard-task-view-button bold" onClick={handleCloseTaskView}>DONE</div>
                        </div>
                    </div>
                </>
                : null
            }
        </>
    )
}

export default TasksCard