import axiosInstance from "../axios/axios_instance"
const http = axiosInstance("/protected",  null, "/dashboard")


const getTodoList = () => http.get('/todo_list');
const newTodo = (body) => http.post('/todo_list', body);
const editTodo = (id, body) => http.put(`/todo_list/${id}`, body);
const deleteTodo = (id) => http.delete(`/todo_list/${id}`);
const getCoachROI = () => http.get(`/coach_roi`);
const getTeamCards = () => http.get('/team_cards')


export default {
    getTodoList,
    newTodo,
    editTodo,
    deleteTodo,
    getCoachROI,
    getTeamCards
}