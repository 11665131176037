import roi_icon from "../../assets/dashboard-roi.svg"
import individual from "../../assets/individual-effectiveness.svg"
import team from "../../assets/team-effectiveness.svg"
import filled_star from "../../assets/teams-filled-star.svg";
import empty_star from "../../assets/teams-empty-star.svg";
import {useEffect, useState} from "react";
import recommended from "../../assets/recommended-thumb.svg";
import Slider from "../Slider";
import info from "../../assets/info-icon.png"
import tick from "../../assets/dashboard-roi-tick.svg"
import dashboard_api from "../../api/protected/dashboard"


const ROICard = () => {

    const starMap = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
    const [roiData, setRoiData] = useState(null)
    const [showBenchmark, setShowBenchmark] = useState(true)

    useEffect(() => {
        getCoachROI()
    }, []);

    const getCoachROI = () => {
        let response = dashboard_api.getCoachROI();
        response.then(response => {
            setRoiData(response.data)
        }).catch(err => console.log(err))
    }

    return (
        <div className="dashboard-roi-card-container" style={{display: roiData?.display ? "" : "none"}}>
            <div className="dashboard-roi-top-row">
                <div className="dashboard-roi-title bold">ROI Details</div>
                <div className="dashboard-roi-details-row">
                    <div className="dashboard-roi-details-main">
                        <div className="dashboard-roi-details-text bold">Coach ROI:</div>
                        <div className="dashboard-roi-name-container regular">{roiData?.coach_name}</div>
                        <div className="dashboard-roi-details-text bold extend-margin">Responses</div>
                        <div className="dashboard-roi-details-responses-text regular">Team:</div>
                        <div className="dashboard-roi-details-responses-value bold">{roiData?.team_count}</div>
                        <div className="dashboard-roi-details-responses-text regular">Participant:</div>
                        <div className="dashboard-roi-details-responses-value bold">{roiData?.user_count}</div>
                    </div>
                    <div className="dashboard-roi-details-report-button bold">
                        <div className="button-text">ROI REPORT</div>
                        <img src={roi_icon} alt="ROI Report"/>
                    </div>
                </div>
            </div>
            <div className="dashboard-roi-main-container">
                <div className="dashboard-roi-main-left">
                    <div className="dashboard-roi-title bold margined">Coach Performance</div>
                    <div className="edit-team-feedback-rating-container dashboard">
                        <div className="edit-team-feedback-rating-icon">
                            <img src={individual} alt="Individual improvement"/>
                        </div>
                        <div className="edit-team-feedback-rating-main">
                            <div className="edit-team-feedback-rating-title regular grey">
                                <span className="bold">TEAM</span> EFFECTIVENESS IMPROVEMENT
                            </div>
                            <div className="edit-team-feedback-rating-stars-container">
                                {starMap.map(idx => {
                                    return (
                                        <div className="feedback-rating-star-container" key={idx}>
                                            <div className="feedback-rating-star">
                                                <img src={roiData?.team_improvement >= idx ? filled_star : empty_star}
                                                     alt="Star"/>
                                            </div>
                                            <div className="feedback-rating-number bold">{idx}</div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                    <div className="edit-team-feedback-rating-container dashboard">
                        <div className="edit-team-feedback-rating-icon">
                            <img src={team} alt="Individual improvement"/>
                        </div>
                        <div className="edit-team-feedback-rating-main">
                            <div className="edit-team-feedback-rating-title regular grey">
                                <span className="bold">INDIVIDUAL</span> EFFECTIVENESS IMPROVEMENT
                            </div>
                            <div className="edit-team-feedback-rating-stars-container">
                                {starMap.map(idx => {
                                    return (
                                        <div className="feedback-rating-star-container" key={idx}>
                                            <div className="feedback-rating-star">
                                                <img src={roiData?.individual_improvement >= idx ? filled_star : empty_star}
                                                    alt="Star"/>
                                            </div>
                                            <div className="feedback-rating-number bold">{idx}</div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                    <div className="edit-team-feedback-rating-container dashboard">
                        <div className="edit-team-feedback-rating-icon">
                            <img src={recommended} alt="Individual improvement"/>
                        </div>
                        <div className="edit-team-feedback-rating-main">
                            <div className="edit-team-feedback-rating-title regular grey">RECOMMENDED</div>
                            <div className="edit-team-feedback-rating-stars-container">
                                {starMap.map(idx => {
                                    return (
                                        <div className="feedback-rating-star-container" key={idx}>
                                            <div className="feedback-rating-star">
                                                <img src={roiData?.recommended >= idx ? filled_star : empty_star}
                                                     alt="Star"/>
                                            </div>
                                            <div className="feedback-rating-number bold">{idx}</div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                    <div className="dashboard-roi-title bold margined">Platform Recommendation</div>
                    <div className="edit-team-feedback-rating-container dashboard">
                        <div className="edit-team-feedback-rating-icon">
                            <img src={recommended} alt="Individual improvement"/>
                        </div>
                        <div className="edit-team-feedback-rating-main">
                            <div className="edit-team-feedback-rating-title regular grey">RECOMMENDED</div>
                            <div className="edit-team-feedback-rating-stars-container">
                                {starMap.map(idx => {
                                    return (
                                        <div className="feedback-rating-star-container" key={idx}>
                                            <div className="feedback-rating-star">
                                                <img src={roiData?.platform_recommended >= idx ? filled_star : empty_star}
                                                     alt="Star"/>
                                            </div>
                                            <div className="feedback-rating-number bold">{idx}</div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="dashboard-roi-main-right">
                    <div className="dashboard-roi-right-title-container">
                        <div className="dashboard-roi-title bold">Performance Over Time</div>
                        <div className="dashboard-roi-slider-container">
                            <Slider state={showBenchmark} update={setShowBenchmark.bind(this)}/>
                            <div className="dashboard-roi-slider-text medium">Add benchmarking data</div>
                        </div>
                    </div>
                    <div className="dashboard-roi-chart-container">
                        <div className="dashboard-roi-chart-title-container">
                            <div className="dashboard-roi-chart-title semi-bold">
                                Overall PERILL Score & Team Function
                            </div>
                            <img src={info} alt="Info"/>
                        </div>
                        <div className="dashboard-roi-thermometer-heading regular">START OF COACHING ENGAGEMENT</div>
                        <div className="dashboard-roi-thermometer-container">
                            <div className="dashboard-roi-thermometer-score-circle bold"
                                 style={{left: `${roiData?.initial_score * 2}%`}}>
                                {roiData?.initial_score}
                            </div>
                            {showBenchmark ?
                                <div className="dashboard-roi-thermometer-score-circle benchmark bold"
                                     style={{left: `${roiData?.benchmark_initial * 2}%`}}>
                                    {roiData?.benchmark_initial}
                                </div> : null
                            }
                            <div className="dashboard-roi-thermometer-outer-band">
                                <div className="dashboard-roi-thermometer-inner-band">
                                    <div className="dashboard-roi-thermometer-section bold low">LOW</div>
                                    <div className="dashboard-roi-thermometer-section bold med">MEDIUM</div>
                                    <div className="dashboard-roi-thermometer-section bold high">HIGH</div>
                                </div>
                            </div>
                        </div>
                        <div className="dashboard-roi-thermometer-markings-container bold">
                            <div className="dashboard-roi-thermometer-marking">0</div>
                            <div className="dashboard-roi-thermometer-marking">50</div>
                        </div>
                        <div className="dashboard-roi-vs bold">VS</div>
                        <div className="dashboard-roi-thermometer-heading regular top-margin">END OF COACHING ENGAGEMENT</div>
                        <div className="dashboard-roi-thermometer-container">
                            <div className="dashboard-roi-thermometer-score-circle bold"
                                 style={{left: `${roiData?.endpoint_score * 2}%`}}>
                                {roiData?.endpoint_score}
                            </div>
                            {showBenchmark ?
                                <div className="dashboard-roi-thermometer-score-circle benchmark bold"
                                     style={{left: `${roiData?.benchmark_end}%`}}>
                                    {roiData?.benchmark_end}
                                </div>
                                : null
                            }
                            <div className="dashboard-roi-thermometer-outer-band">
                                <div className="dashboard-roi-thermometer-inner-band">
                                    <div className="dashboard-roi-thermometer-section bold low">LOW</div>
                                    <div className="dashboard-roi-thermometer-section bold med">MEDIUM</div>
                                    <div className="dashboard-roi-thermometer-section bold high">HIGH</div>
                                </div>
                            </div>
                        </div>
                        <div className="dashboard-roi-thermometer-markings-container bold">
                            <div className="dashboard-roi-thermometer-marking">0</div>
                            <div className="dashboard-roi-thermometer-marking">50</div>
                        </div>
                        <div className="dashboard-roi-performance-change-container">
                            <div className="dashboard-roi-performance-container">
                                <div className="dashboard-roi-performance-title regular">PERFORMANCE % CHANGE</div>
                                <div className="dashboard-roi-performance-value-container bold">
                                    {roiData?.coach_improvement}<span>%</span>
                                    <img src={tick} alt="Improvement" className="dashboard-roi-performance-tick"/>
                                </div>
                            </div>
                            {showBenchmark ? <div className="dashboard-roi-performance-divider"/> : null}
                            {showBenchmark ?
                                <div className="dashboard-roi-performance-container">
                                    <div className="dashboard-roi-performance-title regular">BENCHMARK % CHANGE</div>
                                    <div className="dashboard-roi-performance-value-container benchmark bold">
                                        {roiData?.benchmark_improvement}<span>%</span>
                                    </div>
                                </div> : null
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ROICard