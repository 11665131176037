import {useEffect, useState} from "react";
import Slider from "./Slider";
import del from "../assets/delete-bin.svg";
import edit from "../assets/co-coaches-pen-icon.svg"
import tick from "../assets/co-coaches-tick.svg"
import cross from "../assets/co-coaches-cross.svg"


const CoCoachCoachesGrid = (props) => {

    const [coaches, setCoaches] = useState([])
    const [showPopup, setShowPopup] = useState(false)
    const [storedCoach, setStoredCoach] = useState(null)

    useEffect(() => {
        setCoaches(props.coaches)
    }, [props]);

    const handleDeleteClick = (ev, coach) => {
        if(!ev) return;
        setShowPopup(true);
        setStoredCoach(coach)
    }

    const cancelDelete = (ev) => {
        if(!ev) return;
        setShowPopup(false);
        setStoredCoach(null)
    }

    const acceptDelete = (ev) => {
        if(!ev) return;
        setShowPopup(false)
        props.revoke(storedCoach.id)
        setStoredCoach(null)
    }

    return (
        <>
            <div className="settings-form-container co-coaches">
                <div className="settings-form-title">Your Co-Coaches</div>
                {coaches && coaches.length ?
                    <div className="settings-co-coaches-grid">
                        <div className="settings-co-coaches-row header">
                            <div className="settings-co-coaches-column email">Email</div>
                            <div className="settings-co-coaches-column status">Status</div>
                            <div className="settings-co-coaches-column controls"/>
                        </div>
                        {coaches && coaches.map((coach, idx) => {
                            return (
                                <div className="settings-co-coaches-row" key={idx}>
                                    <div className="settings-co-coaches-column email">{coach.email}</div>
                                    <div className="settings-co-coaches-column status recolour medium">
                                        <div
                                            className={`co-coaches-indicator ${coach.status === "Active" ? "active" : "pending"}`}/>
                                        {coach.status}
                                    </div>
                                    <div className="settings-co-coaches-column controls">
                                        <div className="settings-co-coaches-column-button"
                                             onClick={() => props.handle(coach.id, coach.status)}>
                                            <img src={edit} alt="Edit"/>
                                        </div>
                                        <div className="settings-co-coaches-column-button"
                                             onClick={ev => handleDeleteClick(ev, coach)}>
                                            <img src={del} alt="Delete"/>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                    :
                    <div className="settings-co-coaches-no-coaches-message">You don't have any active co-coaches.</div>
                }
            </div>
            {showPopup ?
                <>
                    <div className="page-mask" onClick={cancelDelete}/>
                    <div className="co-coaches-delete-popup">
                        <div className="co-coachs-delete-popup-title bold">Are you sure you want to delete</div>
                        <div className="co-coaches-delete-popup-text regular">{storedCoach && storedCoach.email}</div>
                        <div className="co-coaches-delete-popup-controls-container">
                            <div className="co-coaches-delete-popup-button">
                                <img src={tick} alt="Yes" onClick={acceptDelete}/>
                            </div>
                            <div className="co-coaches-delete-popup-button">
                                <img src={cross} alt="No" onClick={cancelDelete}/>
                            </div>
                        </div>
                    </div>
                </>
                : null
            }
        </>
    )
}

export default CoCoachCoachesGrid